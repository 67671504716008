import axios, { AxiosResponse } from 'axios';
// constants & types
import { RequestResponse, RequestMethod, RequestConfig } from "../../types";
import {
    URL_LOGIN, URL_LOGOUT, URL_BASE, URL_TIMEOUT, UNAUTHENTICATED_USER, AUTHENTICATION_ERROR,
    CONNECTION_ERROR, REQUEST_ERROR, URL_GET_IF_USER_LOGGED
} from "../../config/Constants";

/**
 * Post authorizate login request to server.
 * Return app response.
 * @param username 
 * @param password 
 */

export async function Login(username: string, password: string): Promise<RequestResponse> {

    const data = { username: username, password: password }
    var config = { data }
    const reqConfig: RequestConfig = {
        method: RequestMethod.post,
        req_url: URL_LOGIN,
        config: config
    }
    return await requestAsync(reqConfig)
}


/**
 * Post authorizate logout request to server.
 * Return app response.
 */
export async function Logout(): Promise<RequestResponse> {
    const reqConfig: RequestConfig = {
        method: RequestMethod.post,
        req_url: URL_LOGOUT,
    }
    return await requestAsync(reqConfig)
}

/**
 * Head request to server if server is online and user is authorizated.
 * Return app response.
 */
export async function getIsUserLogged(): Promise<RequestResponse> {
    const reqConfig: RequestConfig = {
        method: RequestMethod.head,
        req_url: URL_GET_IF_USER_LOGGED,
    }
    return await requestAsync(reqConfig);
}


/**
 * Get request axios function.
 * For check global error request call from AppContext
 * @param {string} req_url - Relative request url joined on the end of URL_BASE from config. Default null.
 * @param {Object} paramsJson - Params key and value for axios get request querystring.
 * Default null.
 * @param {number} eventId - number of actual eventId. Replace "#id" in req_url if present.
 * Default null.
 * @param {Object} deviceList - dictionary object of devices list. If null then do not set in
 * qeuerystring. Format: {5: true, 8: false}. Key is id number of device. Value is boolean
 * if set to querystring. Default null.
 */
export async function getReqAsync(req_url: string, paramsJson?: { [id: string]: string }, eventId?: number, deviceList?: { [id: number]: boolean }) {

    var params = new URLSearchParams(); // init new params variable
    // Check and append input paramsJson argument to params
    if (paramsJson !== undefined) {
        Object.keys(paramsJson).forEach(function (_key: string) {
            params.append(_key, paramsJson[_key].toString());
        })
    }

    // prepare deviceList from localstorage to query string
    if (deviceList !== undefined && Object.keys(deviceList).length > 0) {

        // 	// async call for iterate object
        const prepareDeviceList = async () => {

            Object.keys(deviceList).forEach(function (_key) {
                if (!isNaN(parseInt(_key))) {
                    params.append("device", _key);
                }
            })

        }
        await prepareDeviceList()
        // console.log("4.PREPARE params: " + params)
    }
    const data = await requestAsync({
        method: RequestMethod.get,
        req_url: req_url,
        params: params,
        eventId: eventId
    })
    return data

}


/**
 * Base function for call some api
 * For check global error request call from AppContext
 * @param reqConfig 
 */
export async function requestAsync(reqConfig: RequestConfig): Promise<RequestResponse> {
    var ret: RequestResponse = {
        isAuthorized: false,
        isError: false,
        config: reqConfig
    }
    try {
        // set adding event id to request
        let req_url: string = reqConfig.req_url;
        if (typeof reqConfig.eventId === "number") {
            req_url = req_url.replace("#id", reqConfig.eventId.toString())
        }

        const response: AxiosResponse = await axios({
            method: reqConfig.method,
            baseURL: URL_BASE,
            url: req_url,
            timeout: URL_TIMEOUT,
            params: reqConfig.params || {},
            withCredentials: true,
            ...reqConfig.config || {}
        })
        ret.isAuthorized = true;
        ret.data = response.data;
        ret.responseStatusCode = response.status;
        return ret;

    } catch (error) {
        ret.errorMessageAdmin = error.message;
        ret.isAuthorized = false;
        ret.error = error

        if (error.response) {
            ret.responseStatusCode = error.response.status;
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            if (error.response.status === 401) {
                ret.errorMessageUser = UNAUTHENTICATED_USER;
            } else {
                ret.errorMessageUser = AUTHENTICATION_ERROR;
            }
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the 
            // browser and an instance of
            // http.ClientRequest in node.js
            ret.isError = true;
            ret.errorMessageUser = CONNECTION_ERROR;

        } else {
            // Something happened in setting up the request that triggered an Error
            ret.isError = true;
            ret.errorMessageUser = REQUEST_ERROR;
        }
        return ret;
    }
}
