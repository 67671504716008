import { Route, Switch } from "react-router-dom";

// routes
import AuthenticatedRoute from "./AuthenticatedRoute";
import UnauthenticatedRoute from "./UnauthenticatedRoute";
import NotFoundRoute from "./NotFoundRoute";


// views
import LoginPage from "../../view/LoginPage";
import Soundboard from "../../view/Dashboard/Soundboard";
import Meteoboard from "../../view/Dashboard/Meteoboard";
import Contacts from "../../view/Contacts";

// constants
import { ROUTE_HOMEPAGE_AFTER_LOGIN, ROUTE_METEOBOARD } from "../../config/Constants";

const Routes = () => {
    return (
        <Switch>
            <UnauthenticatedRoute path="/" exact={true} component={LoginPage} />
            <AuthenticatedRoute path={ROUTE_HOMEPAGE_AFTER_LOGIN} exact={true} component={Soundboard} />
            <AuthenticatedRoute path={ROUTE_METEOBOARD} exact={true} component={Meteoboard} />
            <AuthenticatedRoute exact path="/contacts" component={Contacts} />
            <Route component={NotFoundRoute} />
        </Switch>
    );
};
export default Routes;