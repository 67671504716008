
import LineLoading from "../../components/animations/LineLoading";
// import { Container, Row, Col, Badge } from "react-bootstrap";
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import { Badge } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { GraphData } from "../../types/";


const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(0),
        display: 'content',
        overflow: 'hidden',
        flexDirection: 'column',
        borderRadius: "80px",
        backgroundColor: "rgb(232, 242, 255)",
        boxShadow: "0px 4px 6px 0px hsla(0, 0%, 0%, 0.25)",
        margin: 0,
    },
    fixedHeight: {
        height: "450px",
    },
    autoHeight: {
        height: "auto",
    },
    badge: {
        right: 4,
        top: -4,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0px 8px 0px 8px',
    },
}));

/**
 * This components serves as a wrapper for displaying limit pie charts along with a context switching mechanism (back and forward buttons).
 * 
 * @param data - data displayed in content, serves only for displaying loading animation when data is undefined
 * @param content - content to be displayed (this should be either list of StatsPie components or some "No Data" label)
 * @param selectTitle - function to determine title displayed for selection (device, date and time, etc.)
 * @param onForwardCallback - function to call when forward button is pressed
 * @param onBackCallback - function to call when back button is pressed
 * @param bellowLimitColor - color for values bellow limit (in legend)
 * @param inLimitColor - color for values in limit (in legend)
 * @param aboveLimitColor - color for values above limit (in legend)
 */

interface Props {
    data: GraphData[]
    content: JSX.Element
    selectTitle: () => string
    selectColor: () => string | undefined
    onForwardCallback: () => void
    onBackCallback: () => void
    bellowLimitColor?: string
    inLimitColor?: string
    aboveLimitColor?: string
    showLimitLegend?: boolean
}

const StatsPieContainer = (props: Props) => {
    const { data, content,
        selectTitle, selectColor, onForwardCallback, onBackCallback,
        bellowLimitColor = "green",
        inLimitColor = "orange",
        aboveLimitColor = "red" } = props
    const { t, } = useTranslation();

    // styles
    const classes = useStyles();

    const badgeStyle = clsx(classes.paper, classes.badge);

    const limits = (
        <Grid container spacing={2}>
            <Grid item xs={4} md={4} lg={4}>
                <Badge
                    variant="standard"
                    className={badgeStyle}
                    style={{ "backgroundColor": bellowLimitColor }}
                >&nbsp;
                </Badge>
                <span style={{ fontSize: "0.9em" }}>{t("bellow_limit")}</span>
            </Grid>

            <Grid item xs={4} md={4} lg={4}>
                <Badge variant="standard"
                    className={badgeStyle}
                    style={{ "backgroundColor": inLimitColor }}
                >&nbsp;
                </Badge>
                <span style={{ fontSize: "0.9em" }}>Limit +- 2 dB</span>
            </Grid>

            <Grid item xs={4} md={4} lg={4}>
                <Badge variant="dot"
                    className={badgeStyle}
                    style={{ "backgroundColor": aboveLimitColor }}
                >&nbsp;
                </Badge>
                <span style={{ fontSize: "0.9em" }}>{t("over_limit")}</span>
            </Grid>
        </Grid>
    );

    return (
        data.length === 0 ? (
            <LineLoading></LineLoading>
        ) : (
            <Grid container spacing={0} >
                <Grid item xs={12} md={12} lg={12}>

                    <h3
                        style={{ marginTop: "8px" }}
                    >{t("device_statistics")}</h3>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>

                    <IconButton
                        onClick={onBackCallback}
                        style={{
                            color: "rgb(90,15,255)",
                            outline: 0
                        }}
                    >
                        <ArrowBackIosIcon />
                    </IconButton>
                    <span style={{ verticalAlign: "middle", color: selectColor(), fontWeight: "bold", fontSize: 20 }}>
                        {selectTitle()}
                    </span>
                    <IconButton
                        onClick={onForwardCallback}
                        style={{
                            color: "rgb(90,15,255)",
                            outline: 0
                        }}
                    >
                        <ArrowForwardIosIcon />
                    </IconButton>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    {content}
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    { (props.showLimitLegend === true) ? limits : <></> }
                </Grid>
            </Grid>

        )
    );
}

export default StatsPieContainer;