import { useState, useEffect } from "react";
import "./style.css";
import Plot from 'react-plotly.js';
import LineLoading from "../animations/LineLoading"
import { useTranslation } from 'react-i18next';
import { devicesLib } from "../../libs";
import { timerangeFromPlotyRelayoutEvent } from "../../libs/utils/plotly";

import dayjs from "dayjs";

import { isMobile } from "react-device-detect";
import { locales } from "../../config/Constants";
import {
  Devices,
  GraphData,
  SegmentInterval,
  DevicesGraphData,
  TimeRange,
  SoundLimits
} from "../../types";


const defLayout = {
  title: "",
  legend: {
    orientation: "h",
    y: isMobile ? -0.2 : -0.8,
    itemdoubleclick: false
  },
  yaxis: {
    title: 'LAeq,T [dB]',
    fixedrange: true,
  },
  plot_bgcolor: "#d8e4f4",
  xaxis: {
    title: 'Čas',
    type: "date",
    rangeslider: { "visible": !isMobile },
    rangeselector: {
      buttons: [
        {
          count: 1,
          label: "1H",
          step: "hour",
          stepmode: "backward",
        },
        {
          count: 8,
          label: "8H",
          step: "hour",
          stepmode: "backward",
        },
        {
          count: 16,
          label: "16H",
          step: "hour",
          stepmode: "backward",
        },
      ]
    },

  },
}

/*
Function for finding maximum Y (SPL) in given traces.
*/
function findMaxY(data: GraphData[]): number {
  var maxims = data.map((o) => Math.max(...o.raw.y));
  return Math.max(...maxims);
}
interface Props {
  devicesGraphData: DevicesGraphData
  devices: Devices
  selectedInterval: SegmentInterval
  onRelayout: (arg0: TimeRange) => void
  isLoading: boolean
  soundLimits: SoundLimits
}

const DevicesGraph = (props: Props) => {
  const { devicesGraphData, devices, onRelayout,
    selectedInterval, isLoading, soundLimits } = props
  const { t, } = useTranslation();
  const [preparedData, setPreparedData] = useState<Plotly.Data[]>();
  const [layout, setLayout] = useState({});
  const [xRange, setXRange] = useState(["", ""])

  function prepareData(data: DevicesGraphData, updateZoom: boolean) {

    var preparedData: Plotly.Data[] = []
    data.data.forEach(item => {
      if (item.isVisible) {
        if (selectedInterval in item) {
          preparedData.push(
            {
              name: item.name + " (LAeq,T)",
              x: item[selectedInterval].x,
              y: item[selectedInterval].y,
              showlegend: true,
              mode: "lines",
              hovertemplate: '%{y:.1f}',
              line: {
                width: 2,
                color: item.color,
                shape: selectedInterval !== SegmentInterval.raw ? "hv" : "linear"
              }
            }
          )
        }
      }

      setPreparedData(preparedData)
    })

    var date = new Date(data.info.timeRange.end);
    date.setMinutes(date.getMinutes() + 30)
    // find maximum SPL in recievd data
    // this maximum will be used to draw limit shapes
    const maxY = findMaxY(data.data);
    const limitShapeColor = "'rgba(1.0,0,0,0.05)'";
    var annotations = [{
      xref: 'x',
      yref: 'paper',
      x: data.info.timeRange.start.split("T")[0] + " 22:00:00", // night/day ratio
      xanchor: 'center',
      y: 1,
      yanchor: 'bottom',
      text: "limit (den): " +
        soundLimits.limits.day.toString().padStart(3, " ") +
        " dB | limit (noc): " +
        soundLimits.limits.night.toString().padStart(3, " ") +
        " dB",
      showarrow: false
    }];

    var limitLines = [
      // day limit
        {
          type: "line",
          line: {
            color: "black",
            dash: "dot"
          },
          x0: data.info.timeRange.start.split("T")[0] + " 06:00:00 ",
          x1: data.info.timeRange.start.split("T")[0] + " 22:00:00",
          y0: soundLimits.limits.day,
          y1: soundLimits.limits.day
      },
      // night limit
        {
          type: "line",
          line: {
            color: "black",
            dash: "dot"
          },
          x0: data.info.timeRange.start.split("T")[0] + " 22:00:00 ",
          x1: data.info.timeRange.end.split("T")[0] + " 06:00:00",
          y0: soundLimits.limits.night,
          y1: soundLimits.limits.night
        }
    ]

    // dont display limit annotations if limits are disabled
    if (soundLimits.limits.enabled === false) {
      annotations = [];
      limitLines = [];
    }


    var shapes =
      [
        {
          fillcolor: "rgba(63, 81, 181, 0.2)",
          line: { "width": 0 },
          type: "rect",
          x0: data.info.timeRange.start.split("T")[0] + " 22:00:00",
          x1: data.info.timeRange.end.split("T")[0] + " 06:00:00",
          xref: "x",
          y0: 0,
          y1: 1,
          yref: "paper"
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: data.info.timeRange.start.split("T")[0] + " 06:00:00 ",
          x1: data.info.timeRange.start.split("T")[0] + " 22:00:00",
          y0: soundLimits.limits.day,
          y1: Math.max(maxY + 5, soundLimits.limits.day, soundLimits.limits.night),
          fillcolor: limitShapeColor,
          line: { "width": 0 },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: data.info.timeRange.start.split("T")[0] + " 22:00:00 ",
          x1: data.info.timeRange.end.split("T")[0] + " 06:00:00",
          y0: soundLimits.limits.night,
          y1: Math.max(maxY + 5, soundLimits.limits.night, soundLimits.limits.day),
          fillcolor: limitShapeColor,
          line: { "width": 0 },
        },
        ...limitLines,
      ]
    
    var range = []
    if (updateZoom) {
      range = [devicesGraphData.info.timeRange.start, devicesGraphData.info.timeRange.end];
      onRelayout(devicesGraphData.info.timeRange);
      setXRange(range)
    } else {
      range = xRange
    }

    setLayout({
      ...defLayout,
      shapes: shapes,
      annotations: annotations,
      dragmode:"false",
      legend: {
        ...defLayout.legend,
        // tweak for Vltavska event, where the rangeslider is hidden
        y: isMobile || (devices[64] != undefined) ? -0.2 : -0.8,
      },
      xaxis: {
        ...defLayout.xaxis,
        range: range,
        rangeslider: { visible: !isMobile && !(devices[64] != undefined) }, // hide for Vltavska event
        rangeselector: {
          ...defLayout.xaxis.rangeselector,
          visible: devices[64] == undefined // hide range buttons for Vltavska
        }
      },
      margin: {
        // l: 0,
        r: 25,
        t: 15,
      },
      // b: 0,
      font: {
        size: 12,
      },

    })
  }

  useEffect(() => {
    prepareData(devicesGraphData, devicesGraphData.updateZoom)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devicesGraphData]);

  useEffect(() => {
    prepareData(devicesGraphData, false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInterval]);

  function handleRelayout(ev: Plotly.PlotRelayoutEvent) {
    const { start, end } = timerangeFromPlotyRelayoutEvent(ev, devicesGraphData.info.timeRange);
    onRelayout({ start, end })
    setXRange([start, end]);
  }


  return (
    <div className="view-device-graph">
      <div className="view-header" >
        <h3>{t("spl_title")}</h3>
      </div> {

        !preparedData || isLoading ? (
          <div style={{ width: "auto", height: "400px" }}>

            <LineLoading ></LineLoading>
          </div>
        ) : (
          <Plot
            data={preparedData}
            layout={layout}
            style={{ width: "auto", height: "400px" }}
            config={{
              responsive: true,
              displayModeBar: false,
              locale: dayjs.locale(),
              // @ts-ignore
              locales: locales
            }}
            onRelayout={handleRelayout}
          />
        )
      }
    </div >
  );
}

export default DevicesGraph;