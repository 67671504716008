// @ts-ignore
import dayjs from 'dayjs';

import LocalizedFormat from "dayjs/plugin/localizedFormat";
import IsBetween from "dayjs/plugin/isBetween";

import 'dayjs/locale/cs';

// syles & assets & animations & types
import './App.css';
// routes
import Routes from "./services/Routes"

function App() {
  dayjs.locale("cs");
  dayjs.extend(LocalizedFormat);
  dayjs.extend(IsBetween);
  return (
    <div className="App">

      <main className="content">
        <Routes></Routes>
      </main>

    </div>

  );
}

export default App;
