import { useState, useEffect } from "react";
import Plot from 'react-plotly.js';
import LineLoading from "../animations/LineLoading"
import { useTranslation } from 'react-i18next';
import { isMobile } from "react-device-detect";
import {
  Devices, devicesMeteoDataType,
  meteoDataType, TimeRange,
} from "../../types";


// duplicite last values is for close plotly scatterplot line
const Directions = ["S", "SV", "V", "JV", "J", "JZ", "Z", "SZ", "S"]
const countDirection = 8

const defLayout = {
  title: "",
  legend: {
    orientation: "h", "y": isMobile ? -0.2 : 0,
    itemdoubleclick: false,
  },
}



function getWindDirectionRange(windDirection: meteoDataType["wind_directions"], time: TimeRange) {
  var start = new Date(time.start);
  var stop = new Date(time.end);
  var ret = windDirection.filter(function (s) {
    var d = new Date(s.date);
    return d >= start && d < stop;
  });

  return ret;
}


/*
sums array of wind direction values into one array
*/
function windValuesSum(windValues: meteoDataType["wind_directions"]) {
  if (windValues.length < 1)
    return [];


  var ret0: number[] = []
  if (windValues[0].directions.length === 0) {
    return ret0;
  }


  // take sum all rows in current col
  for (let index = 0; index < countDirection; index++) {
    var rowsOfCollumn: number[] = windValues.map(function (value) { return value.directions[index]; });

    var sumRows: number = (rowsOfCollumn.reduce((s, v) => s + v))

    ret0.push(sumRows)
  }
  // Is needed convert frequency numbers to percent
  var allFrequencyCount: number = (ret0.reduce((s, v) => s + v))
  var ret: number[] = []
  ret0.forEach((value) => {
    ret.push((100 * value) / allFrequencyCount)
  })

  ret.push(ret[0]) // for close plotly polar graph line
  return ret;
}



interface Props {
  devicesMeteoData: devicesMeteoDataType
  devices: Devices
  selectedTimeRange: TimeRange
  isLoading: boolean
}

const DevicesPolarWind = (props: Props) => {
  const { devicesMeteoData, devices, selectedTimeRange, isLoading } = props
  const { t, } = useTranslation();
  const [preparedData, setPreparedData] = useState<Plotly.Data[]>();
  const [layout, setLayout] = useState({});

  function prepareData(_devicesSpectrumData: devicesMeteoDataType) {


    var preparedData: Plotly.Data[] = []


    devicesMeteoData.data.forEach(item => {

      if (item.isVisible) {
        var filtred = getWindDirectionRange(item.wind_directions, selectedTimeRange)
        var values = windValuesSum(filtred)

        preparedData.push(
          {
            name: item.name,
            type: "scatterpolar",
            hoverinfo: 'none',
            // marker: item.marker,
            showlegend: true,
            r: values,
            theta: Directions,
            fill: "toself",
            line: {
              color: devices[item.id].color
            },
          }
        )
      }

    })


    setPreparedData(preparedData)




    setLayout({
      ...defLayout,
      margin: {
        // r: 25,
        t: 25,
      },
      font: {
        size: 12,
      },
      polar: {
        radialaxis: {
          angle: 0,
          showticklabels: false
        },
        angularaxis: {
          direction: "clockwise",
          showline: true,
          tickwidth: 10,
          linewidth: 2,
          // gridcolor: "white",
          gridwidth: 2,
          period: 6,
          tickfont: {
            size: 14
          },

          // linecolor: "gray",

        }
      },

    })
  }

  useEffect(() => {
    prepareData(devicesMeteoData)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devicesMeteoData, selectedTimeRange]);


  return (
    <div className="view-device-graph">
      <div className="view-header" >
        <h3>{t("wind_polar_title")}</h3>
      </div> {

        !preparedData || isLoading ? (
          <div style={{ width: "auto", height: "400px" }}>

            <LineLoading ></LineLoading>
          </div>
        ) : (
          <Plot
            data={preparedData}
            layout={layout}
            style={{ width: "auto", height: "400px" }}
            config={{ responsive: true, displayModeBar: false }}
          />
        )
      }
    </div >
  );
}

export default DevicesPolarWind;