import { useState, useEffect } from "react";
import Plot from 'react-plotly.js';
import LineLoading from "../animations/LineLoading"
import { useTranslation } from 'react-i18next';

import dayjs from "dayjs";

import { isMobile } from "react-device-detect";
import {
  devicesMeteoDataType,
  meteoDataType,
  SegmentInterval,
  TimeRange
} from "../../types";
import { locales } from "../../config/Constants";
import { timerangeFromPlotyRelayoutEvent } from "../../libs/utils/plotly";

const defLayout = {
  title: "",
  legend: {
    orientation: "h", "y": isMobile ? -0.2 : -0.8,
    itemdoubleclick: false
  },
  yaxis: {
    title: 'Rychlost větru [m/s]',
  },
  plot_bgcolor: "#d8e4f4",
  xaxis: {
    title: 'Čas',
    type: "date",
    rangeslider: { "visible": !isMobile },
    rangeselector: {
      buttons: [
        {
          count: 1,
          label: "1H",
          step: "hour",
          stepmode: "backward",
        },
        {
          count: 3,
          label: "3H",
          step: "hour",
          stepmode: "backward",
        },
        {
          count: 6,
          label: "6H",
          step: "hour",
          stepmode: "backward",
        },
      ]
    },

  },
}

// Returns the path to the arrow image based requested color and direction
function GetArrowPath(color: string, direction: number): string {
  const dir = Math.round(direction)
  return process.env.PUBLIC_URL + "/arrows/" + dir + "_" + color.replace("#", "") + ".svg"
}

// Return arrow image object for plotly, based on color, direction and date
function GetArrow(date: string, color: string, direction: number) {
  return {
    "source": GetArrowPath(color, direction),
    "xref": "x",
    "yref": "paper",
    "x": date,
    "y": 0.95,
    "sizex": 2 * 24 * 60 * 60 * 1000,
    "sizey": 0.1,
    "xanchor": "center",
    "yanchor": "middle"
  }
}

interface Props {
  devicesMeteoData: devicesMeteoDataType
  selectedInterval: SegmentInterval
  onRelayout: (arg0: TimeRange) => void
  isLoading: boolean
}

const DevicesGraphWind = (props: Props) => {
  const { devicesMeteoData, isLoading, selectedInterval,
    onRelayout } = props
  const { t, } = useTranslation();
  const [preparedData, setPreparedData] = useState<Plotly.Data[]>();
  const [layout, setLayout] = useState({});
  const [xRange, setXRange] = useState(["", ""])

  function prepareData(devicesMeteoData: devicesMeteoDataType, updateZoom: boolean) {

    var data: meteoDataType[] = devicesMeteoData.data
    var preparedData: Plotly.Data[] = []
    var images: any[] = []

    data.forEach(item => {

      if (item.isVisible) {
        if (selectedInterval in item.wind_speed_avg) {
          preparedData.push(
            {
              name: item.name + " (rychlost větru)",
              x: item.wind_speed_avg[selectedInterval].x,
              y: item.wind_speed_avg[selectedInterval].y,
              showlegend: true,
              hovertemplate: '%{y:.1f}',
              line: {
                color: item.color
              }
            }
          )
        }

        item.dominant_wind_direction.x.forEach((e, i) => {
          images.push(GetArrow(e, item.color, item.dominant_wind_direction.y[i]))
        })
      }
    })


    setPreparedData(preparedData)

    var range = []
    if (updateZoom) {
      range = [devicesMeteoData.info.timeRange.start, devicesMeteoData.info.timeRange.end];
      onRelayout(devicesMeteoData.info.timeRange);
      setXRange(range)
    } else {
      range = xRange
    }

    var annotations = [{
      xref: 'paper',
      yref: 'paper',
      x: 1.0,
      xanchor: 'right',
      y: 1,
      yanchor: 'bottom',
      text: "šipky indikují směr proudění větru",
      showarrow: false
    }]

    var shapes =
      [
        {
          fillcolor: "rgba(63, 81, 181, 0.2)",
          line: { "width": 0 },
          type: "rect",
          x0: devicesMeteoData.info.timeRange.start.split("T")[0] + " 22:00:00",
          x1: devicesMeteoData.info.timeRange.end.split("T")[0] + " 06:00:00",
          xref: "x",
          y0: 0,
          y1: 1,
          yref: "paper"
        }
      ]


    setLayout({
      ...defLayout,
      shapes: shapes,
      images: images,
      annotations: annotations,
      xaxis: {
        ...defLayout.xaxis,
        range: range
      },
      margin: {
        r: 50,
        t: 15,
      },
      // b: 0,
      font: {
        size: 12,
      },
    })
  }

  useEffect(() => {
    prepareData(devicesMeteoData, devicesMeteoData.updateZoom)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devicesMeteoData]);

  useEffect(() => {
    prepareData(devicesMeteoData, false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInterval]);

  function handleRelayout(ev: Plotly.PlotRelayoutEvent) {
    const { start, end } = timerangeFromPlotyRelayoutEvent(ev, devicesMeteoData.info.timeRange);
    onRelayout({ start, end });
    setXRange([start, end]);
  }

  return (
    <div className="view-device-graph">
      <div className="view-header" >
        <h3>{t("wind_graph_title")}</h3>
      </div> {

        !preparedData || isLoading ? (
          <div style={{ width: "auto", height: "400px" }}>

            <LineLoading ></LineLoading>
          </div>
        ) : (
          <div>
            <Plot
              data={preparedData}
              layout={layout}
              style={{ width: "auto", height: "400px" }}
              config={{
                responsive: true,
                displayModeBar: false,
                locale: dayjs.locale(),
                // @ts-ignore
                locales: locales
              }}
              onRelayout={handleRelayout}
            />
          </div>
        )
      }
    </div >
  );
}

export default DevicesGraphWind;