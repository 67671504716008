import {
    USER_IS_AUTHENTICATED_KEY, ACTUAL_EVENT_ID, EVENT_MAP_SETTINGS_KEY,
    DEVICES_SELECTED_INTERVAL, EVENT_DEVICES_SELECTED_KEY
} from "../config/Constants";

import {
    Event, EventMapSettings, SelectedDevices,
    SegmentInterval
} from "../types/";

/**
 * Colaborating data with localstorage
 */
const LocalStorage = {
    /**
     * Returns data from local storage
     * @param  {String} key Item to get from the storage
     * @return {String|Object}     LocalStorage from the storage
     */
    get(key: string): any {
        const item = localStorage.getItem(key)
        if (localStorage && typeof item !== "undefined" &&
            item !== "undefined" && item !== null) {
            return JSON.parse(item) || null;
        }

        return null;
    },

    getUserAuthenticated(): boolean {
        return LocalStorage.get(USER_IS_AUTHENTICATED_KEY) || false;
    },

    getActualEvent(): Event {
        return LocalStorage.get(ACTUAL_EVENT_ID) || {};
    },
    getEventMapSettings(): EventMapSettings {
        return LocalStorage.get(EVENT_MAP_SETTINGS_KEY) as EventMapSettings;
    },
    getSelectedTimeInterval(): SegmentInterval {
        return LocalStorage.get(DEVICES_SELECTED_INTERVAL) ? (LocalStorage.get(DEVICES_SELECTED_INTERVAL)) : (SegmentInterval.raw);
    },
    getEventDevicesSelected(): SelectedDevices {
        return LocalStorage.get(EVENT_DEVICES_SELECTED_KEY) || {};
    },
    /**
     * Set data in storage
     * @param {String|Object}  value    The data to store
     * @param {String}  key
     */
    set(value: any, key: string) {
        if (localStorage) {
            return localStorage.setItem(key, JSON.stringify(value));
        }

        return null;
    },
    setUserAuthenticated(value: boolean) {
        return LocalStorage.set(value, USER_IS_AUTHENTICATED_KEY);
    },
    setActualEvent(value: Event) {
        return LocalStorage.set(value, ACTUAL_EVENT_ID);
    },
    setEventMapSettings(value: EventMapSettings) {
        return LocalStorage.set(value, EVENT_MAP_SETTINGS_KEY);
    },
    setSelectedTimeInterval(value: SegmentInterval) {
        return LocalStorage.set(value, DEVICES_SELECTED_INTERVAL);
    },
    setEventDevicesSelected(value: SelectedDevices) {
        return LocalStorage.set(value, EVENT_DEVICES_SELECTED_KEY);
    },


}

export default LocalStorage;
