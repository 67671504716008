import Plot from 'react-plotly.js';
import * as Plotly from 'plotly.js';
import Color from "color";
import { useTranslation } from 'react-i18next';
import { SoundStatistics } from "../../types";


interface Props {
    name: string
    statistics: SoundStatistics
    color?: string
    markerColor?: string
    description?: string
}
/**
 * Widget for displaying how many values were above, below and witihn limit for given device.
 * Note that this widget just displays data passed to it, and does no actual calculation, so
 * technically this can be used to display any kind of values (TODO: refactor name of this widget
 * to reflect this fact).
 * 
 * Color should be either hex string or html name (e. g. 'green').
 * 
 * @param name - name of the device, used as plot title
 * @param statistics - SoundStatistics to be displayed.
 * @param color - base color used for generating pie chart color scale. Pie chart segments will be shades of this color. Alternatively array of three colors, which will be used as bellow, in, and above limit colors.
 * @param markerColor - optionaly draws a circle with selected color in the middle of the pie chart. Does nothing if null.
 * @param description - optional description of the device, rendered bellow name.
 */
const StatsPie = (props: Props) => {
    const { name, statistics, color, markerColor, description } = props;
    const { t, } = useTranslation();

    var colors = ["green", "orange", "red"];
    if (color != null) {
        if (Array.isArray(color)) {
            colors = color.map((c) => { return Color(c).hex() });
        }
        else {
            var baseColor = Color(color);
            colors = [
                baseColor.lighten(0.50).hex(),
                baseColor.lighten(0).hex(),
                baseColor.darken(0.25).hex()
            ]
        }
    }

    var data: Plotly.Data[] = [{
        name: name,
        values: statistics.statistics.map(Math.round),
        labels: [
            t("bellow_limit").toString(),
            'Limit +- 2 dB',
            t("over_limit").toString(),],
        marker: {
            colors: colors
        },
        texttemplate: "%{percent}",
        hovertemplate: "%{percent}",
        type: "pie",
        hole: 0.5,
        sort: false,
        textposition: "inside",
        hoverinfo: "skip",
        direction: "clockwise",
        domain: {
            x: [0],
            y: [0]
        }
    }];

    if (markerColor) {
        // Middle circle
        data.push({
            name: name,
            values: [100],
            labels: ["middle circle"],
            marker: {
                colors: [markerColor]
            },
            type: "pie",
            hole: 0.4,
            sort: false,
            textposition: "none",
            hoverinfo: "skip",
            direction: "clockwise",
            domain: {
                x: [0.3, 0.7],
                y: [0.3, 0.7]
            }
        })
    }

    var layout = {
        margin: {
            l: 10,
            r: 10,
            b: 20,
            t: 10,
        },
        showlegend: false,
        paper_bgcolor: "rgba(0,0,0,0)",
        plot_bgcolor: "rgba(0,0,0,0)"
    }

    return (
        <div className="LimitPercentages">
            <span style={{ fontSize: "1.2em" }}>{name}</span>
            <br />
            { (description) ? <span style={{ fontSize: "1.0em" }}>{description}</span> : <></> }
            <br />
            <span style={{ fontWeight: "bold" }}>{(statistics.leq) ? statistics.leq.toFixed(1) : "--"} dB</span>
            <div>
                <Plot
                    data={data}
                    style={{ width: "auto", minHeight: "180px" }}
                    layout={layout}
                    config={{ responsive: true, displayModeBar: false }}
                />
            </div>
        </div >
    );
}

export default StatsPie;