import { useContext, createContext } from "react";
import {
  RequestResponse, RequestConfig, SoundLimits,
  Event, Events, Devices,
  SelectedDevices, TimeRange
} from "../../types"

// default values
export const defaultIsAuthenticated = false
export const defaultIsAuthenticating = true
export const defaultIsError = false


// type appContext
type contextAppType = {
  isAuthenticated: boolean;
  setIsAuthenticated: (value: boolean) => void;
  isAuthenticating: boolean;
  setIsAuthenticating: (value: boolean) => void;
  isError: boolean;
  setIsError: (value: boolean) => void;
  logout: () => void;
  fetchEvents: () => void;
  getReqAsync: (req_url: string, paramsJson?: { [id: string]: string }, eventId?: number, deviceList?: { [id: number]: boolean }) => Promise<RequestResponse>;
  getDataFromTimeRange: (url: string, timerange: TimeRange, eventId: number) => Promise<RequestResponse>
  requestAsync: (value: RequestConfig) => Promise<RequestResponse>;
  soundLimits: SoundLimits | undefined;
  setSoundLimits: (value: SoundLimits) => void;
  events: Events | undefined;
  actualEvent: Event | undefined;
  handleChangeEvent: (value: Event) => void;
  devices: Devices | undefined;
  selectedDevices: SelectedDevices;
  handleSwitchDeviceList: (id: number, checked: boolean) => void;
  isLoadingDevices: boolean;



};

// appcontext for make provider
export const AppContext = createContext<contextAppType | undefined>(
  undefined
);


// appcontext for use in components
export const useAppContext = () => useContext(AppContext);