import "./style.css";
import 'date-fns';
import { SegmentInterval, Event } from "../../types/";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

import dayjs from 'dayjs';
import DayjsUtils from '@date-io/dayjs';

import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { formatDate, URL_BASE, LEGACY_URL } from "../../config/Constants"

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        padding: 0,
        margin: 0,
    },
    container: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2),
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
    },
    datePicker: {
        padding: 0,
        margin: 0,
    },
    dateArrow: {
        padding: 0,
        margin: 0,
        // display: "flex"
    },
    dateBtn: {
        padding: 0,
        margin: 0,
        borderRadius: 20,
        // borderColor: "#48972A",
        color: "rgb(255,255,255)",
        backgroundColor: "rgba(72, 151, 42,0.9)",
        fontSize: 12,
        height: "100%",
        outline: 0,
        // display: "flex"
    }
}));

interface Props {
    onSelectDate: (arg0: string) => void;
    onSelectInterval: (arg0: SegmentInterval) => void;
    selectedDate: string;
    selectedInterval: string;
    event: Event;
}

function BuildExportUrl(interval: string, date: string, eventId: number): string {
    const end = dayjs(date).add(1, "days").format("YYYY-MM-DD");
    const condense = interval.replace("cnd", "");

    var url = ""
    // use url base constant only if it is defined
    // if it is not defined, url will be relative to the hostname
    if (URL_BASE)
        url = URL_BASE

    url += LEGACY_URL + "/export/" + eventId + "/" + date + "/" + end;
    if (condense !== "raw")
        url += "?condense=" + condense;

    return url;
}

const DevicesDateSelect = (props: Props) => {
    const { onSelectDate, onSelectInterval, selectedDate, selectedInterval } = props
    const classes = useStyles();
    const { t, } = useTranslation();

    const exportUrl = BuildExportUrl(selectedInterval, selectedDate, props.event.id);

    return (
        <div className={classes.root}>
            <Container className={classes.container}>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={12} lg={12} xl={12}>

                        <MuiPickersUtilsProvider utils={DayjsUtils} locale={t("en")} libInstance={dayjs}>
                            <KeyboardDatePicker className={classes.datePicker}
                                margin="normal"
                                id="date-picker-dialog"
                                label={t("Date")}
                                //disableFuture={true}
                                format="L"
                                value={selectedDate}
                                onChange={(date) => {
                                    if (date) {
                                        onSelectDate(dayjs(date.toDate()).format(formatDate))
                                    }
                                }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                style={{ width: "100%" }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item xs={12} sm={6} md={12} lg={12} xl={12}>

                        <Grid container spacing={1}>
                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>

                                <IconButton
                                    className={classes.dateArrow} onClick={() => {
                                        onSelectDate(dayjs(selectedDate).subtract(1, 'days').format(formatDate))
                                    }}
                                    style={{
                                        color: "rgb(90,15,255)",
                                        outline: 0
                                    }}
                                >
                                    <ArrowBackIosIcon />
                                </IconButton>
                            </Grid>

                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                <Button
                                    className={classes.dateBtn}
                                    variant="contained"
                                    onClick={() => {
                                        onSelectDate(dayjs(new Date()).format(formatDate))
                                    }}
                                    style={{
                                        borderRadius: 20,
                                        // borderColor: "#48972A",
                                        color: "rgb(255,255,255)",
                                        backgroundColor: "rgba(72, 151, 42,0.9)",
                                        fontSize: 12,
                                        // height: "100%",
                                        outline: 0,
                                    }}
                                >
                                    {t('Today')}
                                </Button >
                            </Grid>

                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                <IconButton
                                    className={classes.dateArrow} onClick={() => {
                                        onSelectDate(dayjs(selectedDate).add(1, 'days').format(formatDate))
                                    }}
                                    style={{
                                        color: "rgb(90,15,255)",
                                        outline: 0
                                    }}
                                //disabled={dayjs(selectedDate).add(1, "days") > dayjs()}
                                >
                                    <ArrowForwardIosIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormControl style={{ width: "100%" }} >
                            <InputLabel id="simple-select-label-interval">{t('Time interval')}</InputLabel>
                            <Select
                                labelId="simple-select-label-interval"
                                id="simple-select-interval"
                                value={selectedInterval}
                                onChange={(event) => {
                                    var value: string = SegmentInterval.raw
                                    if (typeof event === "string") {
                                        value = event
                                    } else if (typeof event.target.value === "string") {
                                        value = event.target.value
                                    }
                                    onSelectInterval(value as SegmentInterval)
                                }}
                            // displayEmpty
                            >
                                <MenuItem value={SegmentInterval.raw}>{t('Without recalculation')}</MenuItem>
                                <MenuItem value={SegmentInterval.minutes5}>5 {t('Minutes')}</MenuItem>
                                <MenuItem value={SegmentInterval.minutes15}>15 {t('Minutes')}</MenuItem>
                                <MenuItem value={SegmentInterval.hour}>1 {t('Hour')}</MenuItem>
                            </Select>
                            <Button
                                variant="contained"
                                style={{
                                    borderRadius: 20,
                                    color: "rgb(255,255,255)",
                                    backgroundColor: "rgba(72, 151, 42,0.9)",
                                    fontSize: 12,
                                    outline: 0,
                                    margin: "20px",
                                    padding: "5px",
                                    width: "120px",
                                    display: (props.event.id === 21) ? "none" : "block"
                                }}
                                href={exportUrl}
                            >
                                {t('export_timerange')}
                            </Button >
                        </FormControl>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default DevicesDateSelect;