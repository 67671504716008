import { TimeRange, RequestResponse } from "../../types";
import { getReqAsync } from ".";

/**
 * Helper function for extracting time range based data from API.
 */
export async function getDataFromTimeRange(url: string, timerange: TimeRange, eventId: number): Promise<RequestResponse> {
    const start = timerange.start;
    const end = timerange.end;

    return await getReqAsync(url, { start, end }, eventId);
}