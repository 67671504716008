import { useTranslation } from 'react-i18next';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import ListSubheader from '@material-ui/core/ListSubheader';
// import DashboardIcon from '@material-ui/icons/Dashboard';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import Divider from '@material-ui/core/Divider';
import PeopleIcon from '@material-ui/icons/People';
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";

//constants
import {
    ROUTE_HOMEPAGE_AFTER_LOGIN, ROUTE_CONTACTS,
    ROUTE_METEOBOARD
} from "../../config/Constants";

interface Props {
    name: string,
    to: string,
    icon: any
}

const MyItem = (props: Props) => {
    const { name, to, icon } = props
    let location = useLocation();
    return (
        <ListItem
            selected={location.pathname === to ? true : false}
            button
            key={name}
            component={NavLink} to={to}
        >
            <ListItemIcon>
                {icon}
            </ListItemIcon>
            <ListItemText primary={name} />
        </ListItem>
    )
}
const MainListItems = () => {
    const { t, } = useTranslation();
    return (
        <div>
            <MyItem name={t("soundboard")} to={ROUTE_HOMEPAGE_AFTER_LOGIN} icon={<EqualizerIcon></EqualizerIcon>}></MyItem>

            <Divider></Divider>

            <MyItem name={t("meteoboard")} to={ROUTE_METEOBOARD} icon={<AcUnitIcon></AcUnitIcon>}></MyItem>

            <Divider></Divider>
            {/* <ListSubheader inset>Second line</ListSubheader> */}

            <MyItem name={t('Contacts')} to={ROUTE_CONTACTS} icon={<PeopleIcon></PeopleIcon>}></MyItem>

        </div>
    );
}
export default MainListItems
