import { Grid, Paper } from "@material-ui/core";

import { Event } from "../types";

interface Props {
    event: Event
    className: string
}

/**
 * Ad-hoc component for displaying image of measurement point at Vltavska (Filharmonie).
 * Displays nothing for non-Vltavska events.
 */
export default function VltavskaImage({ event, className }: Props) {
    // return empty element for non-Vltavska events
    if(event.id !== 21) return (<></>)

    return (
        <Grid item xs={12} md={12} lg={12}>
            <Paper className={className}>
            <h3>Měřicí místo</h3>
            <img width="95%" style={{ margin: "10px", borderRadius: "5px" }} src="vltavska.jpg" />
            </Paper>
		</Grid>
    )
}