import React from 'react'


import TheLogin from "../components/TheLoginForm";
import Footer from "../components/Footer";

const LoginPage = () => {
    return (
        <div>
            <TheLogin></TheLogin>
            <Footer></Footer>
        </div>
    )
}

export default LoginPage
