import * as React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAppContext } from "../Context/context";

// Constants
import { ROUTE_HOMEPAGE_AFTER_LOGIN } from "../../config/Constants"


interface IProps {
    exact?: boolean;
    path: string;
    component: React.ComponentType<any>;
}

/**
 * If logged redirect to page after login, otherwise
 * pass children component.
 * It is for page view only if not logged.
 * @param param0 
 */
const UnauthenticatedRoute = ({
    component: Component,
    ...otherProps
}: IProps) => {
    const { isAuthenticated } = useAppContext()!;
    return (
        <Route {...otherProps}>
            {!isAuthenticated ? (
                <Component {...otherProps} />
            ) : (
                <Redirect to={ROUTE_HOMEPAGE_AFTER_LOGIN} />
            )}
        </Route>
    )
}
export default UnauthenticatedRoute;