
import {
    Devices,
    SelectedDevices,
    GraphData,
    DeviceWeekData,
    SpectrumData,
    meteoDataType,
    DevicesGraphData
} from "../types/";

import dayjs from "dayjs";

export const devicesLib = {

    addColor(devices: Devices) {
        // does nothing, since device colors are provided by backend API
        // this function is kept here for now due to compatibility
        return devices as Devices;
    },

    /**
     * Split empty space defined by minimum minuteLevel with null value.
     * @param {Object} data - data from server
     * @param {int} minuteLevel - threshold for miss interval
     */
    splitMissed(data: DevicesGraphData, minuteLevel: number): DevicesGraphData {

        data.data.forEach(function (item) {

            var missIndex = []
            for (var i = 0; i < item.raw.x.length - 1; i++) {

                var first = dayjs(item.raw.x[i])
                var next = dayjs(item.raw.x[i + 1])
                var diff = dayjs(next).diff(first, "minutes")
                if (diff > minuteLevel) {
                    missIndex.push(i)
                }
            }
            for (i = 0; i < missIndex.length; i++) {
                item.raw.x.splice(missIndex[i] + i + 1, 0, item.raw.x[missIndex[i]])
                item.raw.y.splice(missIndex[i] + i + 1, 0, NaN)
            }

        })
        return data
    },

    /**
   * From localstorage compare selected devices by id with inData and return in array
   * only selected devices.
   */
    filterDevicesDataBySelected(data: GraphData[], selectedDevices: SelectedDevices): GraphData[] {
        var ret: GraphData[] = this._filterDataBySelected(data, selectedDevices)
        return ret
    },
    /**
   * From localstorage compare selected devices by id with inData and return in array
   * only selected devices.
   */
    filterDevicesSpectrumBySelected(data: SpectrumData[], selectedDevices: SelectedDevices): SpectrumData[] {
        var ret: SpectrumData[] = this._filterDataBySelected(data, selectedDevices)
        return ret
    },
    /**
   * From localstorage compare selected devices by id with inData and return in array
   * only selected devices.
   */
    filterDevicesMeteoBySelected(data: meteoDataType[], selectedDevices: SelectedDevices): meteoDataType[] {
        var ret: meteoDataType[] = this._filterDataBySelected(data, selectedDevices)
        return ret
    },
    /**
   * From localstorage compare selected devices by id with inData and return in array
   * only selected devices.
   */
    filterDevicesWeekDataBySelected(data: DeviceWeekData[], selectedDevices: SelectedDevices): DeviceWeekData[] {
        var ret: DeviceWeekData[] = this._filterDataBySelected(data, selectedDevices)
        return ret
    },
    _filterDataBySelected(data: any[], selectedDevices: SelectedDevices): any[] {
        var isEmptyStorage = false
        if (Object.keys(selectedDevices).length === 0) { isEmptyStorage = true }
        data.forEach(function (item) {
            if (isEmptyStorage === true) {
                item.isVisible = true
            }
            else if (item.id in selectedDevices && selectedDevices[item.id] === true) {
                item.isVisible = true
            } else {
                item.isVisible = false
            }
        })
        return data
    },


    /**
     * Add color from devices to inData by id. The new key "line" with object value "color: red" will be added to output.
     * Return new Object.
     * @param {[Object]} inData - Trace data array. [{x: [1,2,..], y:[5,8,..], name:"test", ...}]
     * @param {Object} devices - Devices dictionary for determine color.
     * {"2":{"date":"2020-05-21","id":2,"name":"Nor140 1","color":"#1973B3"},...}
     */
    addColorArrayData(inData: GraphData[], devices: Devices): GraphData[] {
        inData.forEach(function (item) {
            if (item.id in devices) {
                item.line = {
                    ...item.line,
                    color: devices[item.id].color,
                }
            }
        })
        return inData
    },
    /**
     * Add color from devices to inData by id. The new key "line" with object value "color: red" will be added to output.
     * Return new Object.
     * @param {[Object]} inData - Trace data array. [{x: [1,2,..], y:[5,8,..], name:"test", ...}]
     * @param {Object} devices - Devices dictionary for determine color.
     * {"2":{"date":"2020-05-21","id":2,"name":"Nor140 1","color":"#1973B3"},...}
     */
    addColorArrayDataSpectrum(inData: SpectrumData[], devices: Devices): SpectrumData[] {
        inData.forEach(function (item) {
            if (item.id in devices) {
                item.line = {
                    ...item.line,
                    color: devices[item.id].color,
                }
                item.marker = {
                    ...item.marker,
                    color: devices[item.id].color,
                }
            }
        })
        return inData
    },

    /**
     * @param inData 
     * @returns Return copy data by key isVisible. 
     */
    filterVisibleGraphData(inData: GraphData[]): GraphData[] {
        var data: GraphData[] = this._filterVisibleData(inData)
        return data
    },
    /**
     * @param inData 
     * @returns Return copy data by key isVisible. 
     */
    filterVisibleWeekData(inData: DeviceWeekData[]): DeviceWeekData[] {
        var data: DeviceWeekData[] = this._filterVisibleData(inData)
        return data
    },
    /**
     * @param inData 
     * @returns Return copy data by key isVisible. 
     */
    filterVisibleGprahSpectrum(inData: SpectrumData[]): SpectrumData[] {
        var data: SpectrumData[] = this._filterVisibleData(inData)
        return data
    },
    /**
     * @param inData 
     * @returns Return copy data by key isVisible. 
     */
    _filterVisibleData(inData: any[]): any[] {
        var data: any[] = []

        inData.forEach(function (item) {
            if (item.isVisible) {
                data.push(item)
            }
        })
        return data
    },
}