import { TimeRange, SegmentInterval, GraphData } from "../../types";

/**
 * Function used to extract selected timerange from Ploty.PlotRelayoutEvent.
 */
export function timerangeFromPlotyRelayoutEvent(
    event: Plotly.PlotRelayoutEvent,
    defaultTimeRange: TimeRange
): TimeRange {
    var { start, end } = defaultTimeRange;

    if (event == null || event["autosize"] || event["xaxis.autorange"]) {
        return { start, end };
    } else if (typeof event['xaxis.range[0]'] != "undefined" && typeof event['xaxis.range[1]'] != "undefined") {
        start = String(event['xaxis.range[0]']);
        end = String(event['xaxis.range[1]']);
    }
    else if (typeof event['xaxis.range'] != "undefined") {
        start = event["xaxis.range"][0] as string
        end = event["xaxis.range"][1] as string
    }

    return { start, end };
}