import dayjs from "dayjs";

import { TimeRange } from "../../types";
import { formatDate } from "../../config/Constants";

/**
 * Generates the list of dates in a week that contains given date.
 * E. g. list of dates from monday to sunday from the week where
 * the given date is wednesday.
 */
export function generateWeekDates(date: dayjs.Dayjs): string[] {
    var week: string[] = [];
    for (var i = 0; i < 7; i++) {
        week.push(
            date.startOf("week").add(i, 'days').format(formatDate)
        )
    }

    return week;
}

/**
 * Returns time range that covers the week of the given date.
 * Includes the next monday if necessary.
 */
export function generateWeek(date: dayjs.Dayjs, includeMonday: boolean = true): TimeRange {
    const monday = (includeMonday) ? 1 : 0;
    const start = dayjs(date).startOf("week").format(formatDate);
    const end = dayjs(date).endOf("week").add(monday, 'days').format(formatDate);

    return { start, end }
}

/**
 * Returns time range that covers given day. Offset can be
 * used to move the timerange to given hours (e. g. from 0600 - 0600 next day).
 */
export function generateDay(date: dayjs.Dayjs, offset: number = 0): TimeRange {
    return {
        start: dayjs(date).startOf("day").add(offset, "hours").format(),
        end: dayjs(date).startOf("day").add(offset + 24, "hours").format()
    }
}

/**
 * Rounds the given date to the multiple of minutes parameter. Note that
 * this function also zeroes the seconds and miliseconds.
 * @param date 
 * @param minutes 
 * @param down Rounds down (floor) if true. Otherwise rounds up (ceil).
 * @returns Copy of date with altered minutes.
 */
export function roundToMinutes(date: dayjs.Dayjs, minutes: number, down: boolean): dayjs.Dayjs {
    const round = (n: number) => { return (down) ? Math.floor(n) : Math.ceil(n) };

    const newMinutes = round(date.minute() / minutes) * minutes;
    var newDate;
    newDate = date.minute(newMinutes);
    newDate = newDate.second(0);
    newDate = newDate.millisecond(0);

    return newDate;
}