import "./style.css";
import { useState, useEffect } from "react";
import Plot from 'react-plotly.js';
import LineLoading from "../animations/LineLoading"
import * as Plotly from 'plotly.js';

import dayjs from "dayjs";

import { devicesLib } from "../../libs";
import { generateWeekDates } from "../../libs/utils/time";
import {
	WeekData, Devices, DeviceWeekData,
} from "../../types";
import { formatDate, locales } from "../../config/Constants"

interface Props {
	title: string
	data: WeekData
	eventDevices: Devices
	selectedDate: string
	isLoading: boolean
	layout?: Plotly.Layout
}


const WeekBarSPL = (props: Props) => {
	const { data, eventDevices, layout, title, selectedDate, isLoading } = props
	const [preparedData, setPreparedData] = useState<Plotly.Data[]>();

	const start = dayjs(selectedDate).startOf("week").add(12, 'hours').subtract(1, "days").format("YYYY-MM-DD h:mm");
	const end = dayjs(selectedDate).endOf("week").add(12, 'hours').subtract(1, "days").format("YYYY-MM-DD h:mm");

	const defaultLayout = (): Plotly.Layout => {
		var shapes: Plotly.Shape[] = [] as Plotly.Shape[]
		let weekColor = "rgba(150,150,150,0.2)"
		let weekendColor = "rgba(0,0,0,0.2)"
		let color = ""
		for (var i = 0; i <= 6; i += 1) {
			color = weekColor
			if (i >= 5) {
				color = weekendColor
			}

			var _shapeLine: Plotly.ShapeLine = {
				color: "",
				width: 0,
				dash: "solid",
			}
			shapes.push({

				visible: true,
				layer: 'below',
				type: 'rect',
				path: "",
				xref: 'paper',
				xsizemode: 'scaled',
				xanchor: 0,
				yref: 'paper',
				ysizemode: 'scaled',
				yanchor: 0,
				x0: i / 7,
				y0: 0,
				x1: (i + 1) / 7 - 0.001,
				y1: 1,
				fillcolor: color,
				name: "",
				templateitemname: "",
				opacity: 1,
				line: _shapeLine,

			})
		}

		const ret: Plotly.Layout = {
			font: {
				size: 12,
			},
			yaxis: {
				title: "LAeq,T [dB]",
				fixedrange: true,
				showgrid: false,
			},
			xaxis: {
				fixedrange: true,
				showgrid: false,
				tickformat: "%e.%m. %a",
				type: "date",
			},
			margin: {
				r: 25,
				t: 15,
				b: 15,
			},
			legend: {
				orientation: "h",
			},
			shapes: shapes,

		} as Plotly.Layout
		return ret
	}
	const [inLayout, setInLayout] = useState<Plotly.Layout>(() => {
		if (typeof layout === "undefined" || layout === null) {
			return defaultLayout();
		} else {
			return layout;
		}
	})


	useEffect(() => {
		if (typeof data === "undefined" ||
			typeof eventDevices === "undefined") { return }
		var _data: Plotly.Data[] = prepareData(devicesLib.filterVisibleWeekData(data.devices), eventDevices)
		setPreparedData(_data);

		setInLayout((inLayout) => {
			var shapes = defaultLayout()["shapes"]
			data.dates.forEach(function (item, i) {
				if (item === selectedDate) {
					shapes.push({
						layer: 'above',
						type: "rect",
						xref: "paper",
						yref: "paper",
						x0: i / 7,
						x1: (i + 1) / 7 - 0.001,
						y0: 0.97,
						y1: 1,
						fillcolor: "rgba(90,15,255,1)",

						line: {
							"width": 0,
							"color": "rgba(100,100,100,1)",
						},
					})
				}
				if (item === dayjs().format(formatDate)) {
					shapes.push({
						layer: 'above',
						type: "rect",
						xref: "paper",
						yref: "paper",
						x0: i / 7,
						x1: (i + 1) / 7 - 0.001,
						y0: -0.07,
						y1: 0,
						fillcolor: "rgba(72, 151, 42,0.45)",
						line: {
							"width": 0,
							"color": "rgba(100,100,100,1)",
						},

					})

				}


			})

			var xaxis = {
				...inLayout["xaxis"]!,
				tickvals: generateWeekDates(dayjs(selectedDate)),
				range: [start, end],

			}
			return { ...inLayout, xaxis, shapes }
		});

	}, [data, eventDevices, selectedDate]);

	const prepareData = (inData: DeviceWeekData[], _eventDevices: Devices) => {
		var outData: Plotly.PlotData[] = []
		Object.values(inData).forEach((item: DeviceWeekData, index: number) => {
			var prepareItem: Plotly.PlotData = {} as Plotly.PlotData
			prepareItem.name = item.name

			const week = generateWeekDates(dayjs(selectedDate));
			// this is to make sure that X and Y lists for the graph values
			// will always contain whole week (otherwise the graph will not render correctly)
			// initial zero values are eventualy replaced with the values from the API, if they are present
			prepareItem.x = week;
			prepareItem.y = [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0];

			
			// ad hoc tweak for vltavska, to display day/night values
			if (item.id === 64) {
				var dayData: Plotly.PlotData = {} as Plotly.PlotData;
				var nightData: Plotly.PlotData = {} as Plotly.PlotData;

				dayData.x = week;
				nightData.x = week;
				dayData.y = [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0];
				nightData.y = [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0];

				week.forEach((date: string, i: number) => {
					if (item.x.includes(date)) {
						const itemIndex = item.x.indexOf(date);
						dayData.y[i] = item.y_day[itemIndex];
						nightData.y[i] = item.y_night[itemIndex];
					}
				})

				dayData.name = "Denní období LAeq,16h"
				dayData.type = "bar"
				dayData.text = dayData.y.map((item: Plotly.Datum) => {
					if (item === null) return "";

					const val = item as number;
					return val.toFixed(1).toString();
				})
				dayData.textposition = "auto"
				dayData.hoverinfo = "text"
				dayData.showlegend = true
				dayData.marker = {
					"opacity": 0.9,
					"color": "lighBlue"
				}
				
				nightData.name = "Noční období LAeq,8h"
				nightData.type = "bar"
				nightData.text = nightData.y.map((item: Plotly.Datum) => {
					if (item === null) return "";

					const val = item as number;
					return val.toFixed(1).toString();
				})
				nightData.textposition = "auto"
				nightData.hoverinfo = "text"
				nightData.showlegend = true
				nightData.marker = {
					"opacity": 0.9,
					"color": "darkBlue"
				}

				outData.push(dayData);
				outData.push(nightData);

				return outData;
			}

			week.forEach((date: string, i: number) => {
				if (item.x.includes(date)) {
					const itemIndex = item.x.indexOf(date);
					prepareItem.y[i] = item.y_total[itemIndex];
				}
			})

			// add color by eventDevices
			if (item.id in _eventDevices) {
				prepareItem.type = "bar"
				prepareItem.text = prepareItem.y.map(String)
				prepareItem.textposition = "auto"
				prepareItem.hoverinfo = "text"
				prepareItem.showlegend = true
				prepareItem.marker = {
					"opacity": 0.9,
					"color": _eventDevices[item.id].color,
				}
			}

			outData.push(prepareItem)
		})
		return outData
	}

	return (
		!preparedData || isLoading ? (
			<div style={{ width: "auto", height: "350px" }}>
				<LineLoading ></LineLoading>
			</div>
		) : (
			<div className="view-device-graph" >
				<h3 style={{ marginTop: "8px" }}>{title}</h3>
				<div>
					<Plot
						data={preparedData}
						layout={inLayout}
						style={{ width: "auto", minHeight: "320px" }}
						config={{
							responsive: true,
							displayModeBar: false,
							locale: dayjs.locale(),
							// @ts-ignore
							locales: locales
						}}
					/>
				</div>
			</div >
		)

	);
}

export default WeekBarSPL;
