import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      "Username": "User Name",
      "cannotLogout": "Unable to logout from server.",
      "name": "Name",
      "tag": "Tag",
      "location": "Location",
      "Contacts": "Contacts",
      "name_device": "Device name",
      "laeq_day": "Daytime LAeq",
      "laeq_night": "Nighttime LAeq",
      "view_device": "Show",
      "spl_title": "Time series of acoustic pressure level", // opravit v SPL
      "spectrum_title": "Acoustic spectrum",
      "wind_polar_title": "Wind direction",
      "meteo_graph_title": "Time series of meteo data",
      "device_statistics": "Measurement statistics",
      "over_limit": "Over limit",
      "bellow_limit": "Bellow limit",
      "daytime_statistics": "Daytime",
      "nighttime_statistics": "Nighttime",
      "segment_statistics": "Selected segment",
      "no_values_in_given_range": "No values in given timerange",
      "display_values": "Display values",
      "no_device_selected": "No device selected",
      "grayscale": "Grayscale",
      "indoor": "Indoor",
      "outdoor": "Outdoor",
      "no_devices": "No devices",
      "map": "Map",
      "device_state": "State",
      "export_timerange": "Export XLSX",
      "version": "Version",
      "soundboard": "Sound data",
      "meteoboard": "Meteo data",
      "spectrum_xlabel": "1/3 octave spectrum bands [Hz]",
      "wind_graph_title": "Time series of wind speed data"
    }
  },
  cz: {
    translation: {
      "Username": "Uživatelské jméno",
      "Password": "Heslo",
      "Login": "Přihlásit",
      "Sign In": "Přihlásit se",
      "Invalid Credentials": "Špatné přihlašovací údaje",
      "cannotLogout": "Nelze se momentálně odhlásit ze serveru.",
      "Loading Events": "Načítání akcí",
      "Time segment": "Časový úsek",
      "Time interval": "Časový interval",
      "Name": "Název",
      "name": "Název",
      "Events": "Akce",
      "en": "cs",
      "Date": "Datum",
      "current": "aktuální",
      "Current": "Aktuální",
      "tag": "Tag",
      "Logout": "Odhlásit",
      "location": "Lokace",
      "spl_title": "Časový průběh ekvivalentní hladiny akustického tlaku A",
      "spectrum_title": "Akustické spektrum",
      "wind_polar_title": "Směr větru",
      "meteo_graph_title": "Časový průběh meteo dat",
      "Week statistic": "Týdenní statistika",
      "Without recalculation": "Bez přepočtu",
      //
      "Minute": "Minuta",
      "Minutes": "Minut",
      "Hour": "Hodina",
      //
      "Contacts": "Kontakty",

      "name_device": "Název zařízení",
      "laeq_day": "Denní LAeq, 16h",
      "day": "den",
      "Day": "Den",
      "Today": "Dnes",
      "Whole day": "Celý den",
      "laeq_night": "Noční LAeq, 8h",
      "night": "noc",
      "Night": "Noc",
      "view_device": "Zobrazit",
      "Save": "Uložit",
      "device_statistics": "Statistiky měření",
      "over_limit": "Nad limitem",
      "bellow_limit": "Pod limitem",
      "daytime_statistics": "Denní období",
      "nighttime_statistics": "Noční období",
      "segment_statistics": "Zvolený úsek",
      "no_values_in_given_range": "Žádné hodnoty pro dané období",
      "display_values": "Zobrazit detail",
      "no_device_selected": "Žádné zvolené zařízení",
      "indoor": "Interiér",
      "outdoor": "Exteriér",
      "grayscale": "Černá a bílá",
      "no_devices": "Žádná zařízení",
      "map": "Mapa",
      "device_state": "Stav",
      "export_timerange": "Export XLSX",
      "version": "Verze",
      "soundboard": "Hluková data",
      "meteoboard": "Meteo data",
      "spectrum_xlabel": "Frekvenční pásma po 1/3 oktávách [Hz]",
      "wind_graph_title": "Časový průběh ryhlosti větru"
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "cz",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;