import * as React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAppContext } from "../Context/context";

// Constants
import { ROUTE_IF_NOT_LOGIN, ROUTE_HOMEPAGE_AFTER_LOGIN } from "../../config/Constants"


interface IProps {
    exact?: boolean;
    path: string;
    component: React.ComponentType<any>;
}

/**
 * If logged redirect to page after login, otherwise
 * redirect to page if not login.
 * @param param0 
 */
const NotFoundRoute = ({
    component: Component,
    ...otherProps
}: IProps) => {
    const { isAuthenticated } = useAppContext()!;

    return (
        <Route {...otherProps}>
            {isAuthenticated ? (
                <Redirect to={ROUTE_HOMEPAGE_AFTER_LOGIN} />
            ) : (
                    <Redirect to={ROUTE_IF_NOT_LOGIN} />
                )}
        </Route>
    )
}
export default NotFoundRoute;
