import React from "react";
import "./style.css";
import { useTranslation } from 'react-i18next';
import { Devices, SelectedDevices } from "../../types/";
import DeviceItem from "./item";
import RoomIcon from '@material-ui/icons/Room';
import CheckCircle from '@material-ui/icons/CheckCircle';
import ReportProblem from '@material-ui/icons/ReportProblem';

interface Props {
    devices: Devices
    selectedDevices: SelectedDevices
    onSwitch: (arg0: number, arg1: boolean) => void
    onClickRow: (arg0: number) => void
}
const DevicesList = (props: Props,) => {
    const { devices, selectedDevices, onSwitch, onClickRow } = props
    const { t, } = useTranslation();

    return (
        <div className="devices-container">
            <table className="devices-table" >
                <thead >
                    <tr key={"thead"}>
                        <th key={"selected"}>{t("view_device")}</th>
                        <th key={"name_device"}>{t("name_device")}</th>
                        <th key={"device_state"}>{t("device_state")}</th>
                        <th key={"map"}>{t("map")}</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(devices).map((_key, index) => {
                        const { id, name, warning_flag, color, loc } = devices[_key]
                        const flag = warning_flag === true;

                        return (
                            <tr key={`device-${id}-index-${index}`}>

                                <td key={`device-${id}-switch`}
                                    style={{ paddingLeft: "0%" }}
                                >

                                    <DeviceItem
                                        color_switch={color}
                                        checked={selectedDevices[_key]}
                                        onSwitch={onSwitch}
                                        id={id}
                                    ></DeviceItem>
                                </td>

                                <td key={`device-${name}-name`}>{name}</td>
                                {flag ? (
                                    <td key={`device-${name}-status`}>
                                        <ReportProblem style={{ color: "orange" }} />
                                    </td>
                                ) : (
                                    <td key={`device-${name}-status`}>
                                        <CheckCircle style={{ color: "green" }} />
                                    </td>
                                )}
                                {loc ? (
                                    <td key={`device-${name}-map`} onClick={() => onClickRow(id)}
                                        style={{ cursor: "pointer" }}>
                                        <RoomIcon />
                                    </td>
                                ) : (
                                    <td key={`device-${name}-map`}></td>
                                )}

                            </tr>
                        )
                    })
                    }
                </tbody>

            </table>
        </div>

    );
}

export default DevicesList;
