

/**
 * Through alert show error message
 * @param error error message
 */
export function onError(error: Error | string) {
    let message = error.toString();

    // Auth errors
    if ((error instanceof Error) && error.message) {
        message = error.message;
    }

    alert(message);
}