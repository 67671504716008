import { useState, useEffect } from "react";
import "./style.css";
import Plot from 'react-plotly.js';
import LineLoading from "../animations/LineLoading"
import { useTranslation } from 'react-i18next';
import { devicesLib } from "../../libs"

import { isMobile } from "react-device-detect";
import {
  Devices, DevicesSpectrumData,
  SpectrumData, TimeRange,
} from "../../types";


const defLayout = {
  title: "",
  legend: {
    orientation: "h", "y": isMobile ? -0.2 : -0.4,
    itemdoubleclick: false,
  },
  yaxis: {
    title: 'Lf [dB]',
    range: [20, 85]
  },
  plot_bgcolor: "#d8e4f4",
  xaxis: {
    rangeslider: { "visible": false },
  },

}


/*
sums array of LEQ values, same way as ams.utils.leq_sum
*/
function leqSum(data: number[]) {
  var a: number[] = data.map(v => Math.pow(10, v / 10));
  var ret: number = (a.reduce((s, v) => s + v)) / a.length;
  ret = 10 * Math.log10(ret)

  return ret;
}

function getSpectrumRange(spectrums: SpectrumData["spectrums"], time: TimeRange) {
  var start = new Date(time.start);
  var stop = new Date(time.end);
  var ret = spectrums.filter(function (s) {
    var d = new Date(s.date);
    return d >= start && d < stop;
  });

  return ret;
}


/*
sums array of 1/3 octave spectrums into one spectrum, same as
ams.utils.leq_spectrum_sum
*/
function leqSpectrumSum(spectrums: SpectrumData["spectrums"]) {
  if (spectrums.length < 1)
    return [];

  // take sum all rows in current rows
  var ret: number[] = []
  if (spectrums[0].spectrum.length === 0) {
    return ret;
  }

  spectrums[0].spectrum.forEach((_value, index) => {
    var rowsOfCollumns = spectrums.map(function (value) { return value.spectrum[index]; });
    ret.push(leqSum(rowsOfCollumns))
  });


  return ret;
}


var x: string[] = ["10 Hz", "13 Hz", "16 Hz", "20 Hz", "25 Hz", "32 Hz", "40 Hz", "50 Hz",
  "63 Hz", "80 Hz", "100 Hz", "125 Hz", "160 Hz", "200 Hz", "250 Hz", "315 Hz", "400 Hz",
  "500 Hz", "630 Hz", "800 Hz", "1 kHz", "1.25 kHz", "1.6 kHz", "2 kHz", "2.5 kHz",
  "3.15 kHz", "4 kHz", "5 kHz", "6.3 kHz", "8 kHz", "10 kHz", "12.5 kHz", "16 kHz", "20 kHz"]



interface Props {
  devicesSpectrumData: DevicesSpectrumData
  devices: Devices
  selectedTimeRange: TimeRange
  isLoading: boolean
}

const DevicesSpectrum = (props: Props) => {
  const { devicesSpectrumData, devices, selectedTimeRange, isLoading } = props
  const { t, } = useTranslation();
  const [preparedData, setPreparedData] = useState<Plotly.Data[]>();
  const [layout, setLayout] = useState({});

  function prepareData(_devicesSpectrumData: DevicesSpectrumData) {

    var data = devicesLib.addColorArrayDataSpectrum(devicesSpectrumData.data, devices)
    data = devicesLib.filterVisibleGprahSpectrum(data)

    var preparedData: Plotly.Data[] = []

    data.forEach(item => {
      var filtred = getSpectrumRange(item.spectrums, selectedTimeRange)
      var ret_y = leqSpectrumSum(filtred)
      preparedData.push(
        {
          name: item.name,
          x: x,
          y: ret_y,
          type: "bar",
          marker: item.marker,
          showlegend: true,
          hovertemplate: "%{y:.1f} dB"
        }
      )

    })

    setPreparedData(preparedData)




    setLayout({
      ...defLayout,
      xaxis: {
        title: t('spectrum_xlabel'),
        ...defLayout.xaxis,
      },
      margin: {
        r: 25,
        t: 15,
      },
      font: {
        size: 12,
      },

    })
  }

  useEffect(() => {
    prepareData(devicesSpectrumData)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devicesSpectrumData, selectedTimeRange]);


  return (
    <div className="view-device-graph">
      <div className="view-header" >
        <h3>{t("spectrum_title")}</h3>
      </div> {

        !preparedData || isLoading ? (
          <div style={{ width: "auto", height: "250px" }}>

            <LineLoading ></LineLoading>
          </div>
        ) : (
          <Plot
            data={preparedData}
            layout={layout}
            style={{ width: "auto", height: "250px" }}
            config={{ responsive: true, displayModeBar: false }}
          />
        )
      }
    </div >
  );
}

export default DevicesSpectrum;