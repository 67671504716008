import { useState, useMemo } from "react";
import "./style.css";
import {
    SoundLimits
} from "../../types/";
import LineLoading from "../animations/LineLoading"
import Slider from '@material-ui/core/Slider';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col } from "react-bootstrap"
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';

const minLimit = 20
const maxLimit = 120

interface Props {
    onChangeConfig: (arg0: SoundLimits, arg1: boolean) => void
    soundLimits: SoundLimits,
    isEventConfigLoading: boolean
}

function DevicesSettings(props: Props) {
    const { onChangeConfig, soundLimits, isEventConfigLoading } = props
    const { t, } = useTranslation();
    const [dayLimit, setDayLimit] = useState(0);
    const [nightLimit, setNightLimit] = useState(0);
    const [isSaved, setIsSaved] = useState(true);

    const StyledBadge = withStyles((theme) => ({
        badge: {
            right: 0,
            top: 0,
            border: `2px solid ${theme.palette.background.paper}`,
            backgroundColor: "#F7931C",
        },
    }))(Badge);

    function marks() {
        var mark = []
        for (var i = minLimit; i <= maxLimit; i += 20) {
            mark.push({
                value: i,
                label: i,
            })
        }
        return mark
    }
    useMemo(() => {
        // console.log("1. DeviceSetting config: " + JSON.stringify(soundLimits))
        if (typeof soundLimits !== "undefined" &&
            isSaved === true
        ) {
            setDayLimit(soundLimits.limits.day)
            setNightLimit(soundLimits.limits.night)
        }
    }, [soundLimits, isSaved]);


    function saveSetting() {
        // note that enabled will be ingored by backend
        const data: SoundLimits = { limits: { day: dayLimit, night: nightLimit, enabled: true } }
        onChangeConfig(data, false)
        setIsSaved(true)
    }

    function resetSetting() {
        // note that enabled will be ingored by backend
        const data: SoundLimits = { limits: { day: dayLimit, night: nightLimit, enabled: true } }
        onChangeConfig(data, true)
        setIsSaved(true)
    }


    function valueDayChange(_: any, value: number | number[]) {
        if (typeof value === "number") {
            setDayLimit(value)
        }
    }

    function valueNightChange(_: any, value: number | number[]) {
        if (typeof value === "number") {
            setNightLimit(value)
        }
    }

    const handleIsSaved = () => {
        setIsSaved(false)
    }

    // if sound limits are disabled, return empty element
    if (soundLimits != undefined && soundLimits.limits.enabled === false) { 
        return <div></div>
    }

    return (

        typeof soundLimits === "undefined" ||
            isEventConfigLoading === true ? (
            <LineLoading></LineLoading>
        ) : (
            <div className="config">
                <Paper className="paper" elevation={0}>

                    <Typography id="discrete-slider-always" gutterBottom>
                        L<sub>Aeq</sub> {t("day")} limit - <b>{soundLimits.limits.day}</b>
                    </Typography>
                    <Slider
                        value={dayLimit}
                        onChangeCommitted={handleIsSaved}
                        onChange={valueDayChange}
                        aria-labelledby="discrete-slider-always"
                        step={1}
                        marks={marks()}
                        valueLabelDisplay="auto"
                        min={minLimit}
                        max={maxLimit}
                    />
                </Paper>
                <Paper className="paper" elevation={0}>
                    <Typography id="discrete-slider-always" gutterBottom>
                        L<sub>Aeq</sub> {t("night")} limit - <b>{soundLimits.limits.night}</b>
                    </Typography>
                    <Slider
                        value={nightLimit}
                        onChangeCommitted={handleIsSaved}
                        onChange={valueNightChange}
                        aria-labelledby="discrete-slider-always"
                        step={1}
                        marks={marks()}
                        valueLabelDisplay="auto"
                        min={minLimit}
                        max={maxLimit}
                    />
                </Paper>

                <Container style={{ margin: "10px auto 0 auto" }}>
                    <Row>
                        <Col>
                            <Button
                                variant="outlined"
                                onClick={resetSetting}
                                style={{
                                    borderRadius: 25,
                                    borderColor: "#48972A",
                                    color: "black",
                                    fontSize: 12,
                                    height: "100%",
                                    outline: 0,
                                }}
                            >
                                {t('Reset')}
                            </Button >

                            {' '}
                            <StyledBadge invisible={isSaved} >
                                <Button
                                    variant="contained"
                                    onClick={saveSetting}
                                    style={{
                                        borderRadius: 25,
                                        backgroundColor: "#48972A",
                                        color: "white",
                                        fontSize: 12,
                                        height: "100%",
                                        outline: 0,

                                    }}
                                >
                                    {t('Save')}
                                </Button >
                            </StyledBadge >
                        </Col>
                    </Row>
                </Container>
            </div>
        )

    );
}

export default DevicesSettings;
