import { LatLng } from 'leaflet';

export interface ICurrent {
    isAuthenticated: boolean;
    uuid: string | null;
}


//////////////////////////////////////////////////
// EVENTS
//////////////////////////////////////////////////
export interface Events {
    events: { [id: string]: Event },
}
export interface Event {
    devices: [id: number],
    id: number,
    name: string
}
export interface EventMapSettings {
    showValues: boolean
}
//////////////////////////////////////////////////
// DEVICES
//////////////////////////////////////////////////
export enum DataType {
    spl = "spl",
    spl_spectrum = "spl_spectrum",
    meteo = "meteo",
}

export interface Devices {
    [key: string]: Device

}

export interface SelectedDevices {
    [key: string]: boolean
}

export interface Device {
    id: number;
    warning_flag: boolean;
    name: string;
    color: string;
    tag?: string;
    laeq_day?: number;
    laeq_day_status?: boolean;
    laeq_hour?: number;
    laeq_hour_status?: boolean;
    laeq_night?: number;
    laeq_night_status?: boolean;
    loc?: Loc;
    tile_layer?: TileLayerInfo;
}

/**
 * Describes the limit related statistics of some range of SPL values.
 * Used in DeviceStatistics widget.
 */
export interface SoundStatistics {
    statistics: number[] // either [bellowLimit, inLimit, aboveLimit] or []
    leq?: number // LEQ for given values
}

interface Loc {
    lat: number;
    lng: number;
}

export interface TileLayerInfo {
    name: string;
    url: string;
    size_x: number;
    size_y: number;
    drawing_scale: number;
    units_per_pixel: number;
    blocks: PolygonInfo[]
}
export interface PolygonInfo {
    points: LatLng[];
    name: string
}

//////////////////////////////////////////////////
// SEGMENT DATA
//////////////////////////////////////////////////
export enum SegmentInterval {
    raw = "raw",
    minutes5 = "cnd5t",
    minutes15 = "cnd15t",
    hour = "cnd1h"
}

//////////////////////////////////////////////////
// GRAPH DATA
//////////////////////////////////////////////////
export interface DevicesGraphData {
    data: GraphData[];
    info: DataInfo;
    updateZoom: boolean;

}

export interface DevicesSpectrumData {
    data: SpectrumData[];
    info: DataInfo;
    updateZoom: boolean;

}



/**
 * Information about sound limits for day/night.
 */
export interface SoundLimits {
    limits: {
        day: number,
        night: number
        enabled: boolean
    }
}

/**
 * Additional information about data, provided by Noiseportal API.
 */
export interface DataInfo {
    limits: SoundLimits
    timeRange: TimeRange
}

export interface SpectrumData {
    data_type: string;
    id: number;
    name: string;
    spectrums: Array<
        {
            date: string;
            spectrum: number[]
        }>
    isVisible: boolean;
    line?: Line;
    showlegend?: boolean,
    marker?: {}
}

/**
 * SPL graph data.
 */
export interface GraphData {
    data_type: string;
    id: number;
    color: string;
    name: string;
    raw: coordSplType;
    cnd1h: coordSplType;
    cnd5t: coordSplType;
    cnd15t: coordSplType;
    isVisible: boolean;
    mode?: string;
    showlegend?: boolean,
    line?: Line;
    marker?: {}
}

interface Line {
    color?: string;
    width?: number;
    dash?: 'solid' | 'dot' | 'dash' | 'longdash' | 'dashdot' | 'longdashdot';
    shape?: 'linear' | 'spline' | 'hv' | 'vh' | 'hvh' | 'vhv';
    smoothing?: number;
    simplify?: boolean;
}

interface coordSplType {
    x: string[];
    y: number[];
}

/**
 * Generic time range type.
 */
export interface TimeRange {
    start: string
    end: string
}

/**
 * Week data for multiple devices.
 */
export interface WeekData {
    dates: string[]
    devices: DeviceWeekData[]
}

/**
 * Week data for single device.
 */
export interface DeviceWeekData {
    id: number;
    name: string;
    x: string[];
    y_day: number[];
    y_night: number[];
    y_total: number[];
    isVisible: boolean;
}

//////////////////////////////////////////////////
// METEO DATA
//////////////////////////////////////////////////
interface graphValues {
    raw: coordSplType;
    cnd1h: coordSplType;
    cnd5t: coordSplType;
    cnd15t: coordSplType;
    x: string[];
    y: number[];
}
export interface devicesMeteoDataType {
    data: meteoDataType[];
    info: DataInfo;
    updateZoom: boolean;

}
export interface meteoDataType {
    data_type: string;
    id: number;
    name: string;
    color: string;
    wind_speed_avg: graphValues;
    wind_directions: Array<
        {
            date: string;
            directions: number[]
        }>
    dominant_wind_direction: graphValues;
    temperature: graphValues;
    precipitation: graphValues;
    isVisible: boolean;
    line?: Line;
    showlegend?: boolean,
    marker?: {}
}

export interface RequestResponse {
    isAuthorized: boolean,
    isError: boolean,
    config: RequestConfig,
    errorMessageUser?: string,
    errorMessageAdmin?: string,
    data?: any,
    responseStatusCode?: number
    error?: any
}
/**
 * Arguments setting for request
 * @param username
 * @param req_url
 * @param params
 * @param eventId
 * @param config
 */
export interface RequestConfig {
    method: RequestMethod,
    req_url: string,
    params?: {},
    eventId?: number,
    config?: {}
}

export enum RequestMethod {
    get = "get",
    head = "head",
    post = "post"
}

//////////////////////////////////////////////////
// ANIME
//////////////////////////////////////////////////
export interface animOptions {
    loop: boolean,
    autoplay: boolean,
    animationData: {},
}



