import "./style.css";
import ReactLoading from "react-loading";

const LineLoading = () => {

    return (
        <ReactLoading className="anim-line-loading" type={"bars"} color={"black"} />
    );
}


export default LineLoading
