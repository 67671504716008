import { makeStyles } from '@material-ui/core/styles';


export const useBaseStyles = makeStyles((theme) => ({
	container: {
		padding: 0,
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
	},
	paper: {
		padding: theme.spacing(0),
		display: 'content',
		overflow: 'hidden',
		flexDirection: 'column',
		borderRadius: "8px",
		backgroundColor: "rgb(255, 255, 255)",
		boxShadow: "0px 4px 6px 0px hsla(0, 0%, 0%, 0.25)",
		margin: 0,
	},
	eventsSelect: {
		height: "auto",
	},
	fixedHeight: {
		height: "28em",
	},
	mapDevices: {
		height: "260px"
	},
	autoHeight: {
		height: "auto",
	},
}));

