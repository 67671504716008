import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Login } from "../../libs/callApi";
import { useAppContext } from "../../services/Context/context";
// syles & assets
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import "./TheLoginForm.css"

// components
import LoaderButton from "../items/LoaderButton";



const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const TheLogin = () => {
  const classes = useStyles();
  const { t, } = useTranslation();
  const { setIsAuthenticated, fetchEvents } = useAppContext()!;

  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    setIsLoading(true);
    const ret = await Login(username, password);
    setPassword("");
    setErrorMessage(t(ret.errorMessageUser || ""));
    setIsLoading(false);
    setIsAuthenticated(ret.isAuthorized); // has to be on last place!
    if (ret.isAuthorized) { await fetchEvents() }
  }
  const isValidatedForm = (): boolean => {
    return username.length > 0 && password.length > 0;
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t('Sign In')}
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label={t('Username')}
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={(value) => {
              setUsername(value.target.value)
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label={t('Password')}
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(value) => {
              setPassword(value.target.value)
            }}
          />
          <LoaderButton
            type="submit"
            variant="contained"
            fullWidth={true}
            color="primary"
            className={classes.submit}
            name={t('Login')}
            isLoading={isLoading}
            disabled={!isValidatedForm()}
          ></LoaderButton>
          {errorMessage &&
            <Typography component="h1" variant="h6">
              {errorMessage}
            </Typography>
          }
        </form>
      </div>
    </Container>
  );
}

export default TheLogin