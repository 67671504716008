interface Props {
    name: string
    unit: string
    value?: number
    labelFontSize?: number | string
    description?: string
}

/**
 * Widget for displaying single numerical value (like LAeq). This is a replacement
 * for StatsPie widget for events without set sound limits.
 */
const StatsValueDisplay = (props: Props) => {
    const { name, value, unit, labelFontSize, description } = props;
    const fontSize = labelFontSize ? labelFontSize : "3.5vw";
    return (
        <div>
            <span style={{ fontSize: "1.2em" }}>{name}</span>
            <br />
            { (description) ? <span style={{ fontSize: "1.0em" }}>{description}</span> : <></> }
            <br />
            <span style={{ fontWeight: "bold", fontSize: fontSize}}>{(value) ? value.toFixed(1) : "--"}</span>
            <br />
            <span>{unit}</span>
        </div>
    );
}

export default StatsValueDisplay;