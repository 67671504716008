import { ReactNode, useState, useEffect, useMemo } from "react";

import {
    AppContext, defaultIsAuthenticated,
    defaultIsAuthenticating, defaultIsError,
} from "./context";

import { URL_EVENTS, URL_EVENT_DEVICES } from "../../config/Constants"
import LocalStorage from "../../libs/localStorage";
import {
    RequestConfig, RequestResponse, SoundLimits,
    Events, Event, Devices, SelectedDevices, TimeRange
} from "../../types"

// libs
import {
    getIsUserLogged, Logout,
    getReqAsync as _getReqAsync,
    requestAsync as _requestAsync
} from "../../libs/callApi";

import { getDataFromTimeRange as _getDataFromTimeRange } from "../../libs/callApi/data";

import { devicesLib } from "../../libs";
import { onError } from "../../libs/errorLib";

type Props = {
    children: ReactNode
};



/**
 * Component for pass context constants into wraped children components.
 * @param param0
 */
export const ContextProvider = ({
    children
}: Props) => {
    const [isAuthenticated, setIsAuthenticated] = useState(defaultIsAuthenticated);
    const [isAuthenticating, setIsAuthenticating] = useState(defaultIsAuthenticating);
    const [isError, setIsError] = useState(defaultIsError);
    const [errorMsg, setErrorMsg] = useState("");
    // events
    const [events, setEvents] = useState<Events | undefined>();
    const [actualEvent, setActualEvent] = useState<Event | undefined>();
    // devices
    const [devices, setDevices] = useState<Devices | undefined>();
    const [selectedDevices, setSelectedDevices] = useState<SelectedDevices>(LocalStorage.getEventDevicesSelected());
    const [soundLimits, setSoundLimits] = useState<SoundLimits | undefined>();
    const [isLoadingDevices, setIsLoadingDevices] = useState<boolean>(false);

    /**
     * Logout from server. If connection is ok then
     * setIsAuthenticated to false.
     */
    const logout = async () => {
        const ret = await Logout();
        if (!ret.isError) {
            setIsAuthenticated(false);
            setEvents(undefined)
            setDevices(undefined)
            setActualEvent(undefined)
        } else {
            onError(ret.errorMessageUser || "");
        }
    }

    const getReqAsync = async (req_url: string, paramsJson?: { [id: string]: string }, eventId?: number, deviceList?: { [id: number]: boolean }): Promise<RequestResponse> => {
        const ret: RequestResponse = await _getReqAsync(req_url, paramsJson, eventId, deviceList);
        setIsError(ret.isError);
        return ret
    }
    const requestAsync = async (reqConfig: RequestConfig): Promise<RequestResponse> => {
        const ret: RequestResponse = await _requestAsync(reqConfig);
        setIsError(ret.isError);
        return ret
    }

    const getDataFromTimeRange = async (url: string, timerange: TimeRange, eventId: number): Promise<RequestResponse> => {
        const ret = await _getDataFromTimeRange(url, timerange, eventId);
        setIsError(ret.isError);
        return ret;
    }


    const fetchEvents = async () => {
        // Load Events
        var _inData = await getReqAsync(URL_EVENTS, undefined, undefined, undefined)
        var inData: Events = _inData.data

        if (Object.keys(inData.events).length > 0) {
            // If Events
            setEvents(inData)
            const _actualevent: Event = getActualEvent(inData)
            handleChangeEvent(_actualevent)
        }
    }

    // Some stuff for first render of page
    useEffect(() => {
        // control if user is logged
        const authenticate = async () => {
            setIsAuthenticating(true);
            var ret = await getIsUserLogged();
            setIsAuthenticated(ret.isAuthorized);
            setIsAuthenticating(false);
            setErrorMsg(ret.errorMessageUser || "")
            setIsError(ret.isError)

            if (ret.isAuthorized) {
                await fetchEvents()
            }
        }

        authenticate();
        // TODO: do budoucna vlozit sem nacteni zakladni
        // hlavicky uzivatele pripadne events ?

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useMemo(() => {
        if (isAuthenticated) {
            const fetchDevices = async (event: Event | undefined) => {
                // Load Events
                if (!event) { return }
                setIsLoadingDevices(true)
                // console.log("start fetch data content, event: ", event.id)
                var _inData = await getReqAsync(URL_EVENT_DEVICES, undefined, event.id, undefined)
                var inData = _inData.data
                // console.log("end fetch data content", _inData, typeof _inData)
                if (!_inData.isError && inData && Object.keys(inData).length > 0) {
                    // add color to devices
                    var devicesData: Devices = devicesLib.addColor(inData)
                    // If devices
                    setDevices(devicesData)

                    //set default selected devices to all
                    var _selectedDevices: SelectedDevices = {}
                    Object.keys(devicesData).forEach((key) => {
                        _selectedDevices[key] = true
                    });
                    setSelectedDevices(_selectedDevices)
                }
                setIsLoadingDevices(false)
            }
            fetchDevices(actualEvent)

        }
    }, [actualEvent, isAuthenticated]);


    /**
    * If present return last clicked event id from localstorage or
    * return first evend id from eventsList or
    * if not find id return undefined.
    */
    const getActualEvent = (data: Events) => {
        const eventStorage = LocalStorage.getActualEvent()
        if (eventStorage) {
            // compare income server events with localstorage
            for (let key in data.events) {
                if (JSON.stringify(data.events[key]) === JSON.stringify(eventStorage)) {
                    // if finded then return actual
                    return data.events[key]
                }
            }
        }
        // default value
        return Object.values(data.events)[0]
    }

    // HANDLERS
    const handleChangeEvent = (event: Event) => {
        LocalStorage.setActualEvent(event)
        setActualEvent(event)
    }


    const handleSwitchDeviceList = (id: number, checked: boolean) => {
        var _selectedDevices = { ...selectedDevices, [id]: checked }
        LocalStorage.setEventDevicesSelected(_selectedDevices)
        setSelectedDevices(_selectedDevices)
    }

    return (

        isError ? (
            <div>Error page {errorMsg}</div>
        ) : (
            isAuthenticating ? (
                <div>Loading...</div>
            ) : (<AppContext.Provider value={{
                isAuthenticated, setIsAuthenticated,
                isAuthenticating, setIsAuthenticating,
                isError, setIsError, fetchEvents,
                logout, getReqAsync, getDataFromTimeRange, requestAsync,
                soundLimits, setSoundLimits, events,
                actualEvent, handleChangeEvent, devices,
                selectedDevices, handleSwitchDeviceList,
                isLoadingDevices
            }}>
                {children}
            </AppContext.Provider >
            )
        )
    )
};