// import React from 'react'
import Grid from '@material-ui/core/Grid';
import { useEffect, useState } from 'react';
import { useAppContext } from "../../../services/Context/context";
import { URL_EVENT_CONFIG, ROUTE_METEOBOARD } from "../../../config/Constants";
import LocalStorage from "../../../libs/localStorage";
import LineLoading from "../../../components/animations/LineLoading"
import {
	Event, SegmentInterval,
	RequestConfig, RequestMethod, SoundLimits
} from "../../../types";
import clsx from 'clsx';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";

import dayjs from "dayjs";

import { formatDate, ROUTE_HOMEPAGE_AFTER_LOGIN } from "../../../config/Constants"
// syles & assets
import { useBaseStyles } from "../../../styles"

// components
import DeviceMap from "../../../components/DeviceMap";
import DevicesList from "../../../components/DevicesList";
import DevicesSettings from "../../../components/DevicesSettings";
import DevicesDateSelect from "../../../components/DevicesDateSelect";
import SoundData from "./SoundData"
import MeteoData from "./MeteoData"

import VltavskaImage from '../../../components/VltavskaImage';

const useStyles = makeStyles({
	root: {
		position: "sticky",
		top: "5rem",
	},

});

interface Props {
	event: Event
}

const DashboardContent = (props: Props) => {
	const { getReqAsync, requestAsync, setSoundLimits,
		soundLimits, devices, selectedDevices,
		handleSwitchDeviceList, isLoadingDevices } = useAppContext()!;
	// Props
	const { event } = props
	let location = useLocation();

	// styles
	const classes = useBaseStyles();
	const classesBase = useStyles();

	// stats
	const [isLimitsLoading, setLimitsIsLoading] = useState<boolean>(false);
	const [mapCenterDevice, setMapCenterDevice] = useState<number>(
		() => {
			if (devices) {
				return Number(Object.keys(devices)[0])
			}
			else {
				return -1;
			}
		}
	);


	// Date time / picker segments
	const [selectedDate, setSelectedDate] = useState<string>(() => {
		// var today = new Date(2021, 4, 11);
		return dayjs().format(formatDate)
	});

	const [selectedInterval, setSelectedInterval] = useState<SegmentInterval>(LocalStorage.getSelectedTimeInterval());

	useEffect(() => {
		const fetchLimits = async () => {
			if (location.pathname === ROUTE_HOMEPAGE_AFTER_LOGIN) {
				var inData = await getReqAsync(URL_EVENT_CONFIG, undefined, event.id, undefined)
				if (!inData.isError && inData.data) {
					setSoundLimits(inData.data)
				}
			}
		}
		fetchLimits()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [event]);


	// HANDLERS
	const handleGpraphLimits = async (config: SoundLimits, setDefault: boolean) => {
		setLimitsIsLoading(true)
		if (setDefault) {
			// TODO:Zatim nemame, musime dodat
		} else {

			const config_post: RequestConfig = {
				method: RequestMethod.post,
				req_url: URL_EVENT_CONFIG,
				params: {},
				eventId: event.id,
				config: { data: config }
			}
			var inData = await requestAsync(config_post)
			if (!inData.isError && inData.data) {
				setSoundLimits(inData.data)
			}
		}


		setLimitsIsLoading(false)
	}


	function handleSelectDate(value: string) {
		setSelectedDate(value);

	}


	const handleSelectInterval = (value: SegmentInterval) => {
		setSelectedInterval(value);
		LocalStorage.setSelectedTimeInterval(value);
	}


	const handleCenterDevice = (value: number) => {
		setMapCenterDevice(value);
	}


	const leftMenu = clsx(classesBase.root);
	const mapPaper = clsx(classes.paper, classes.mapDevices);
	const autoPaper = clsx(classes.paper, classes.autoHeight);
	return (
		<div>
			{devices && selectedDevices && event && !isLoadingDevices ? (
				<Container maxWidth={false} className={classes.container}>

					<Grid container spacing={3}>

						{/* LEFT MENU  */}
						<Grid item xs={12} sm={12} md={4} lg={3} >
							<Grid container spacing={2} className={leftMenu}>

								<Grid item xs={12} md={12} lg={12}>
									<Paper className={autoPaper}>
										<DevicesList
											devices={devices}
											selectedDevices={selectedDevices}
											onSwitch={handleSwitchDeviceList}
											onClickRow={handleCenterDevice}
										></DevicesList>
									</Paper>
								</Grid>
								<Grid item xs={12} md={12} lg={12} >
									<Paper className={autoPaper}>
										<DevicesDateSelect
											event={event}
											onSelectDate={handleSelectDate}
											onSelectInterval={handleSelectInterval}
											selectedDate={selectedDate}
											selectedInterval={selectedInterval}
										></DevicesDateSelect>
									</Paper>
								</Grid>
								{soundLimits &&
									location.pathname === ROUTE_HOMEPAGE_AFTER_LOGIN
									? (
										<Grid item xs={12} md={12} lg={12}>
											<Paper className={autoPaper}>
												<DevicesSettings
													onChangeConfig={handleGpraphLimits}
													soundLimits={soundLimits}
													isEventConfigLoading={isLimitsLoading}
												></DevicesSettings>
											</Paper>
										</Grid>

									) : (
										<div></div>
									)}
								
								<VltavskaImage event={event} className={autoPaper}></VltavskaImage>
							</Grid>
						</Grid>
						{/* CONTENT  */}
						<Grid item xs={12} sm={12} md={8} lg={9} >
							<Grid container spacing={2}>
								<Grid item xs={12} md={12} lg={12} >
									<Paper className={mapPaper}>
										<DeviceMap
											devices={devices}
											soundLimits={soundLimits}
											selectedDevices={selectedDevices}
											centeredDevice={mapCenterDevice}
											onCenteredDevice={handleCenterDevice}
										></DeviceMap>
									</Paper>
								</Grid>

								{soundLimits &&
									location.pathname === ROUTE_HOMEPAGE_AFTER_LOGIN
									? (
										<Grid item xs={12} md={12} lg={12}>
											<SoundData
												event={event}
												devices={devices}
												selectedDevices={selectedDevices}
												selectedInterval={selectedInterval}
												selectedDate={selectedDate}
												isEnabled={location.pathname === ROUTE_HOMEPAGE_AFTER_LOGIN}
											></SoundData>
										</Grid>
									) : (
										<Grid item xs={12} md={12} lg={12}>
											<MeteoData
												event={event}
												devices={devices}
												selectedDevices={selectedDevices}
												selectedInterval={selectedInterval}
												selectedDate={selectedDate}
												isEnabled={location.pathname === ROUTE_METEOBOARD}
											></MeteoData>
										</Grid>
									)}

							</Grid>
						</Grid>
					</Grid>
				</Container>
			) : (
				<div style={{ width: "auto", height: "400px" }}>
					<LineLoading ></LineLoading>
				</div>
			)}
		</div>
	)
}

export default DashboardContent

