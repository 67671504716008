import "./style.css";
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';




interface Props {
    id: number
    color_switch: string
    checked: boolean
    onSwitch: (arg0: number, arg1: boolean) => void
}
const DeviceItem = (props: Props) => {
    const {id, color_switch,checked,onSwitch} = props
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                paddingLeft: 0,
                width: 42,
                height: 18,
                padding: 0,
                margin: theme.spacing(0.2),
            },
            switchBase: {
                paddingTop:2,
                paddingLeft:4,
                '&$checked': {
                    padding: 0,
                    paddingTop:2,
                    transform: 'translateX(24px)',
                    color: theme.palette.common.white,
                    '& + $track': {
                        backgroundColor: color_switch,
                        opacity: 1,
                        border: 'none',
                    },
                },
                '&$focusVisible $thumb': {
                    color: '#52d869',
                    border: '8px solid #fff',
                },
            },
            thumb: {
                transform: 'translateX(0px)',
                width: 14,
                height: 14,
            },
            track: {
                borderRadius: 26 / 2,
                border: `1px solid ${theme.palette.grey[400]}`,
                backgroundColor: theme.palette.grey[50],
                opacity: 1,
                transition: theme.transitions.create(['background-color', 'border']),
            },
            checked: {},
            focusVisible: {},
        }),
    );
    const classess = useStyles();

    return (
        <Switch
        style={{display:"block"}}
            focusVisibleClassName={classess.focusVisible}
            disableRipple
            classes={{
                root: classess.root,
                switchBase: classess.switchBase,
                thumb: classess.thumb,
                track: classess.track,
                checked: classess.checked,
            }}
            checked={checked}
            onChange={() => onSwitch(id, !checked)}
        />

    );
}

export default DeviceItem;
