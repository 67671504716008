import Color from "color";
import dayjs from "dayjs";

import { SoundLimits } from "../../types";

/**
 * Returns color based on value (SPL, LEQ, etc.) and given sound limits. Checks if the
 * value exceeds the limit for either night or day, based on time parameter.
 * 
 * Both value and limits can be undefined, in which case the black color is returned.
 */
export function limitsToColors(value: number | undefined, limits: SoundLimits | undefined, time: dayjs.Dayjs): Color {
    if (!value)
        return Color("black");
    if (!limits)
        return Color("black");


    const isNight = time.hour() >= 22 || time.hour() <= 6;
    if (isNight && value > limits.limits.night)
        return Color("red");

    if (value > limits.limits.day)
        return Color("red");

    return Color("black");
}