import React, { useState, useRef, useEffect } from 'react';
import { Button } from '@material-ui/core';
import Control from 'react-leaflet-control';
import { Map, TileLayer, CircleMarker, Tooltip, LayersControl, ImageOverlay, Polygon } from 'react-leaflet';
import { useTranslation } from 'react-i18next';
import LocalStorage from "../../libs/localStorage";
import { limitsToColors } from "../../libs/utils/colors";
import { useBaseStyles } from "../../styles"
import {
    Device, EventMapSettings, TileLayerInfo, PolygonInfo, SelectedDevices,
    Devices,
    SoundLimits
} from "../../types"

import dayjs from "dayjs";

import 'leaflet/dist/leaflet.css';
import './DeviceMap.css';
import { CRS, LatLng, LayersControlEvent, } from 'leaflet';

/**
 * Recalculates drawing coordinates to map unit coordinates.
 * Assumes that map units equals pixels.
 * @param drawingX X coordinate in drawing.
 * @param drawingY Y coordinate in drawing.
 * @param tl TileLayerInfo from which drawing scale and units per pixel will be extracted.
 */
function getRecalculatedCoordinates(drawingX: number, drawingY: number, tl: TileLayerInfo): LatLng {
    const newX = drawingX * tl.drawing_scale / tl.units_per_pixel;
    const newY = drawingY * tl.drawing_scale / tl.units_per_pixel;

    return new LatLng(newY, newX);
}

/**
 * Finds the first device with defined location. If no
 * device has defined location or the list is empty,
 * returns default (Prague) location.
 */
function getFirstLocation(devices: Devices): LatLng {
    const devList = Object.values(devices);
    for (let i = 0; i < devList.length; i++) {
        const device = devList[i];
        if (device.loc && device.loc.lat && device.loc.lng) {
            return new LatLng(device.loc.lat, device.loc.lng);
        }
    }

    return new LatLng(50.0858828, 14.5067667);
}

interface Props {
    devices: Devices,
    selectedDevices: SelectedDevices
    centeredDevice: number
    onCenteredDevice: (arg0: number) => void
    soundLimits: SoundLimits | undefined
};

/**
 * Expected data input expressed as API url: /api/content/event/<id>/devices
 * @param devices - Object returned from /api/content/event/<id>/devices.
 * @param selectedDevices
 * TODO: eventualne odstranit ten kod co pracuje s custom vrstvama, aby to bylo prozatim jednodussi
 * TODO: react-leaflet 3.0, https://sean-rennie.medium.com/migrating-react-leaflet-from-v2-to-v3-12d6088af191
 * Working without react-leaflet-control in 3.0: https://github.com/LiveBy/react-leaflet-control/issues/44
 */
function DeviceMap(props: Props) {
    const { devices, selectedDevices, centeredDevice, onCenteredDevice, soundLimits } = props
    const [defaultCenter, setDefaultCenter] = useState<LatLng | undefined>(getFirstLocation(devices));
    const defaultZoom = 13;
    const { t, } = useTranslation();
    const classes = useBaseStyles();

    let layerSet: TileLayerInfo[] = []
    Object.values(devices).forEach((device) => {
        const tl = device.tile_layer;
        if (tl && !layerSet.includes(tl)) { layerSet.push(tl) }
    })

    const [currentTileLayer, setCurrentTileLayer] = useState<TileLayerInfo | null>(layerSet[0] || null);
    const [isLocal, setIsLocal] = useState<boolean>(false);
    const [hoveredPolygon, setHoveredPolygon] = useState<boolean>(false);

    // some notes about why null https://github.com/DefinitelyTyped/DefinitelyTyped/issues/35572
    // these references are used for switching between interior and exterior map
    // (they are required for proper map redrawing)
    const globalMapRef = useRef<Map | null>(null);
    const localMapRef = useRef<Map | null>(null);

    const [isFixedHover, setFixedHover] = useState(() => {
        const settings: EventMapSettings = LocalStorage.getEventMapSettings();
        return settings ? settings.showValues : true
    });

    function makeLayerControl(tl: TileLayerInfo | undefined) {
        if (tl === undefined) return undefined;
        var checked = false;
        if (currentTileLayer != null)
            checked = currentTileLayer.name === tl.name;

        return (<LayersControl.BaseLayer name={tl.name} key={tl.name} checked={checked}>
            <ImageOverlay url={tl.url}
                bounds={[[0, 0], [tl.size_x, tl.size_y]]}
            />

        </LayersControl.BaseLayer>)
    }
    const layerControls = layerSet.map(makeLayerControl).filter(l => l);
    // Creates a JSX pin component to be placed in the map. Note
    // that function returns undefined if given pinData has no location.
    function makePin(pinData: Device, i: number) {
        var tlPerm = null;
        var tl = null;
        if (pinData.loc == null)
            return undefined;

        if (pinData.loc.lat == null && pinData.loc.lng == null)
            return undefined;

        if (selectedDevices[pinData.id] === true) {
            const tooltipColor = limitsToColors(pinData.laeq_hour, soundLimits, dayjs());
            const tlElem = (
                <Tooltip className="DeviceMapPinHoverText" permanent={isFixedHover} >
                    <b>{pinData.name}</b>

                    {pinData.id !== 64 &&
                        <>
                            < br />
                            LAeq, T=1h: <span style={{ fontWeight: "bold", color: tooltipColor.toString() }}>
                                {pinData.laeq_hour ? pinData.laeq_hour : "--"} dB
                            </span>
                            <br />
                        </>
                    }
                </Tooltip>
            );

            if (isFixedHover === true) tlPerm = tlElem;
            else tl = tlElem;
        }

        var center = new LatLng(pinData?.loc?.lat, pinData?.loc?.lng);
        if (pinData.tile_layer != null)
            center = getRecalculatedCoordinates(pinData?.loc?.lng, pinData?.loc?.lat, pinData.tile_layer);

        return (
            <CircleMarker
                key={pinData.id}
                center={center}
                color={pinData.color}
                fillOpacity={1.0}
                fillColor={selectedDevices[pinData.id] ? pinData.color : "#999"}
                radius={10}
                weight={2}
            >
                {tlPerm}
                {tl}
            </CircleMarker >
        );
    }

    function makePolygon(data: PolygonInfo) {
        return (<Polygon
            key={data.name}
            color={hoveredPolygon === true ? "rgba(0, 255, 0, 0.3)" : "rgba(0,0,0,0)"}
            positions={data.points}
            onmouseover={() => setHoveredPolygon(true)}
            onmouseout={() => setHoveredPolygon(false)}>
            <Tooltip>
                {data.name}
            </Tooltip>
        </Polygon>)
    }

    // render polygons from current tile layer
    var polygons: JSX.Element[] = [];
    if (currentTileLayer != null && currentTileLayer.blocks != null)
        polygons = currentTileLayer.blocks.map(makePolygon);

    var globalPins = [];
    var localPins = [];
    if (devices != null) {
        const data = Object.keys(devices).map((k): Device => devices[k]);
        for (var i = 0; i < data.length; i++) {
            const pinData = data[i];
            const pin = makePin(pinData, i);

            if (pin === undefined)
                continue;

            if (pinData.tile_layer == null) {
                globalPins.push(makePin(pinData, i));
                continue;
            }

            if (currentTileLayer == null)
                continue;

            if (pinData.tile_layer.name === currentTileLayer.name)
                localPins.push(makePin(pinData, i));
        }
    }

    const handleCheckbox = () => {
        setFixedHover(() => !isFixedHover);

        var mapSettings = LocalStorage.getEventMapSettings()
        LocalStorage.setEventMapSettings({
            ...mapSettings,
            showValues: !isFixedHover
        });
    }

    useEffect(() => {
        globalMapRef?.current?.leafletElement.invalidateSize();
        localMapRef?.current?.leafletElement.invalidateSize();

        const updateCenterByName = () => {
            if (centeredDevice) {
                Object.keys(devices).forEach(key => {
                    if (String(centeredDevice) === key) {

                        if (devices && devices[key] && devices[key].loc) {
                            var center = new LatLng(devices[key]!.loc!.lat!, devices[key]!.loc!.lng!);
                            setDefaultCenter(center)
                        }
                    }

                }
                )
            }
        }
        updateCenterByName()

    }, [isLocal, centeredDevice, devices])

    const handleMapSwitch = () => {
        setIsLocal(() => !isLocal);
    }
    const handleViewportChanged = () => {
        setDefaultCenter(undefined)
        onCenteredDevice(-1)
    }

    const handleMapLayerChange = (e: LayersControlEvent) => {
        var newLayer = layerSet.find(
            (l) => {
                if (l == null) return false;
                return e.name === l.name;
            }
        );
        setCurrentTileLayer(newLayer || null);
    }

    const mapControls = (
        <Control position="topright">
            <div className="MapControl">
                <span style={{ marginRight: "5px" }}>
                    {t("display_values")}
                </span>
                <label className="switch">
                    <input type="checkbox"
                        onChange={handleCheckbox}
                        checked={isFixedHover}
                    />
                    <span className="slider round"></span>
                </label>
                <br />
                {
                    // draw the switch only if we have some layer to switch to
                    layerSet.length > 0 ? (
                        <Button onClick={handleMapSwitch} variant="outlined">
                            {(isLocal) ? t("indoor") : t("outdoor")}
                        </Button>)
                        :
                        (<div></div>)
                }
            </div>
        </Control>
    )

    const globalMap = (
        <div className="GoogleMap" hidden={isLocal}>
            <Map
                ref={globalMapRef}
                center={defaultCenter}
                zoom={defaultZoom}
                id="globalMapId"
                onViewportChanged={() => { handleViewportChanged() }}
                className={classes.mapDevices}
            >
                <LayersControl position="bottomleft" >
                    < LayersControl.BaseLayer name="OSM" key="osm" checked>
                        <TileLayer
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                    </LayersControl.BaseLayer>

                </LayersControl>

                {globalPins}

                {mapControls}
            </Map>
        </div >
    );

    const localMap = (
        <div className="GoogleMap" hidden={!isLocal}>
            <Map
                ref={localMapRef}
                id="localMapId"
                zoom={0}
                minZoom={0}
                maxZoom={0}
                center={[500, 500]}
                crs={CRS.Simple}
                onbaselayerchange={handleMapLayerChange}
                className={classes.mapDevices}
            >
                <LayersControl position="bottomleft" >
                    {layerControls}
                </LayersControl>

                {localPins}

                {polygons}

                {mapControls}
            </Map>
        </div >
    );

    return (
        <div>
            {devices === undefined || selectedDevices === undefined ? (
                <div>
                    {t("no_devices")}
                </div>

            ) : (
                <div>
                    {globalMap}
                    {localMap}
                </div>
            )}
        </div>
    )
};

export default DeviceMap;