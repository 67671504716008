// import React from 'react'
import { useEffect, useState, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';
import LineLoading from "../../../components/animations/LineLoading"
import { useAppContext } from "../../../services/Context/context";
import { DEVICES_SPL_GRAPH_URL, DEVICES_WEEK_URL, DEVICES_SPECTRUM_GRAPH_URL } from "../../../config/Constants";
import {
	Devices,
	SelectedDevices,
	Event,
	DevicesGraphData,
	SegmentInterval, TimeRange,
	WeekData, DevicesSpectrumData
} from "../../../types";
import clsx from 'clsx';
import { devicesLib } from "../../../libs";

import dayjs from "dayjs";

import { generateDay, generateWeek } from '../../../libs/utils/time';
// syles & assets
import { useBaseStyles } from "../../../styles"

// components
import DevicesGraph from "../../../components/DevicesGraph";
import DevicesSpectrum from "../../../components/DevicesSpectrum";
import DeviceStatistics from "../../../components/DeviceStatistics";
import WeekBarSPL from "../../../components/WeekBarSPL";

interface Props {
	event: Event
	devices: Devices
	selectedDevices: SelectedDevices
	selectedInterval: SegmentInterval
	selectedDate: string
	isEnabled: boolean
}

const SoundData = (props: Props) => {
	const { getDataFromTimeRange, soundLimits } = useAppContext()!;

	// Props
	const { event, devices, selectedDevices,
		selectedInterval, selectedDate, isEnabled } = props

	const { t, } = useTranslation();

	// styles
	const classes = useBaseStyles();

	// stats
	const [isLoadingGraph, setIsLoadingGraph] = useState<boolean>(false);
	const [isLoadingSpectrum, setIsLoadingSpectrum] = useState<boolean>(false);
	const [isLoadingWeekBar, setIsLoadingWeekBar] = useState<boolean>(false);

	// Graph devices stats
	const [devicesGraphData, setDevicesGraphData] = useState<DevicesGraphData>();
	const [devicesSpectrumData, setDevicesSpectrumData] = useState<DevicesSpectrumData>();

	// //  for statics widget
	const [devicesGraphTimerange, setDevicesGraphTimerange] = useState<TimeRange>(
		generateDay(dayjs(selectedDate), 6)
	);

	// weekData stats
	const [devicesWeekData, setDevicesWeekData] = useState<WeekData>();

	useEffect(() => {
		if (!isEnabled) { return }
		// FETCH DATA
		const fetchEventDevicesGraph = async (updateZoom: boolean) => {
			if (event === null) { return; }
			setIsLoadingGraph(true)

			// GRAPH DATA
			const _inData = await getDataFromTimeRange(
				DEVICES_SPL_GRAPH_URL,
				generateDay(dayjs(selectedDate), 6),
				event.id);

			var inData: DevicesGraphData = _inData.data

			if (inData && Object.keys(inData.data).length > 0) {
				inData = devicesLib.splitMissed(inData, 1)
				inData.data = devicesLib.filterDevicesDataBySelected(inData.data, selectedDevices)
				inData.updateZoom = updateZoom
				setDevicesGraphData(inData)
			}

			setIsLoadingGraph(false)
		}

		const fetchEventWeekData = async () => {
			if (devicesWeekData && devicesWeekData.dates.includes(selectedDate)) {
				// If we are in the same week, we dont have to fetch new data
			} else {
				setIsLoadingWeekBar(true);

				const _inData = await getDataFromTimeRange(
					DEVICES_WEEK_URL,
					generateWeek(dayjs(selectedDate)),
					event.id
				);

				var inData: WeekData = _inData.data

				if (inData) {
					setDevicesWeekData({
						dates: inData.dates,
						devices: devicesLib.filterDevicesWeekDataBySelected(inData.devices, selectedDevices)
					})
				}
				setIsLoadingWeekBar(false)
			}
		}

		fetchEventDevicesGraph(true)
		fetchEventWeekData()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedDate, event, soundLimits]);

	useEffect(() => {
		if (!isEnabled) { return }
		const fetchEventSepctrumGraph = async (updateZoom: boolean) => {
			setIsLoadingSpectrum(true)
			// SPECTRUM DATA

			const _sp = await getDataFromTimeRange(
				DEVICES_SPECTRUM_GRAPH_URL,
				generateDay(dayjs(selectedDate), 6),
				event.id);

			var sp: DevicesSpectrumData = _sp.data as DevicesSpectrumData

			if (sp && sp.data.length > 0) {
				sp.data = devicesLib.filterDevicesSpectrumBySelected(sp.data, selectedDevices)
				sp.updateZoom = updateZoom
				setDevicesSpectrumData(sp)
			}
			setIsLoadingSpectrum(false)
		}

		fetchEventSepctrumGraph(true)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedDate, event]);


	useMemo(() => {
		if (!isEnabled) { return }

		if (devicesGraphData) {
			setDevicesGraphData({
				...devicesGraphData,
				data: devicesLib.filterDevicesDataBySelected(devicesGraphData.data, selectedDevices)
			})
		}
		if (devicesSpectrumData) {
			setDevicesSpectrumData({
				...devicesSpectrumData,
				data: devicesLib.filterDevicesSpectrumBySelected(devicesSpectrumData.data, selectedDevices)
			})
		}

		if (devicesWeekData) {
			setDevicesWeekData({
				...devicesWeekData,
				devices: devicesLib.filterDevicesWeekDataBySelected(devicesWeekData.devices, selectedDevices)
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedDevices]);


	function handleDevicesGraphRelayout(data: TimeRange) {
		setDevicesGraphTimerange(data);
	}

	const autoPaper = clsx(classes.paper, classes.autoHeight);
	const fixedPaper = clsx(classes.paper, classes.fixedHeight);

	return (
		<div>
			{devices && selectedDevices ? (
				// {devices && selectedDevices && !isLoading ? (
				<div>
					{devicesGraphData && devicesWeekData && soundLimits &&
						devicesSpectrumData ? (
						<Grid container spacing={2}>

							<Grid item xs={12} md={12} lg={12}>
								<Paper className={autoPaper}>
									<DevicesGraph
										soundLimits={soundLimits}
										devicesGraphData={devicesGraphData}
										devices={devices}
										selectedInterval={selectedInterval}
										onRelayout={handleDevicesGraphRelayout}
										isLoading={isLoadingGraph}
									></DevicesGraph>
								</Paper>
							</Grid>
							{
								devicesSpectrumData.data.length > 0 &&
									devicesSpectrumData.data.some(d => d.spectrums.length > 0)
									? (
										<Grid item xs={12} md={12} lg={12}>
											<Paper className={autoPaper}>
												<DevicesSpectrum
													devicesSpectrumData={devicesSpectrumData}
													devices={devices}
													selectedTimeRange={devicesGraphTimerange}
													isLoading={isLoadingSpectrum}
												></DevicesSpectrum>
											</Paper>
										</Grid>
									) : (
										<div></div>
									)}

							<Grid item xs={12} sm={12} md={12} lg={6}>
								<Paper className={fixedPaper}>
									<DeviceStatistics
										soundLimits={soundLimits}
										eventDevices={devices}
										devicesData={devicesGraphData}
										selectedTimeRange={devicesGraphTimerange}
										selectedDevices={selectedDevices}
										isLoading={isLoadingGraph}
									></DeviceStatistics>
								</Paper>
							</Grid>

							<Grid item xs={12} sm={12} md={12} lg={6}>
								<Paper className={fixedPaper}>
									<WeekBarSPL
										title={t("Week statistic")}
										data={devicesWeekData}
										eventDevices={devices}
										selectedDate={selectedDate}
										isLoading={isLoadingWeekBar}
									></WeekBarSPL>
								</Paper>
							</Grid>

						</Grid>

					) : (
						<div style={{ width: "auto", height: "400px" }}>
							<LineLoading ></LineLoading>
						</div>
					)}
				</div>
			) : (
				<div style={{ width: "auto", height: "400px" }}>
					<LineLoading ></LineLoading>
				</div>
			)
			}
		</div >
	)
}

export default SoundData

