// This is a workaround for loading different locales in Plotly React components in typescript.
// See https://github.com/plotly/react-plotly.js/issues/99#issuecomment-854459975.
// Also note the ts-ignore.
// @ts-ignore
import csLocale from "plotly.js-locales/cs";

//////////////////////////////////////////////////////////
// !!!! ALL TIMEOUT are in [ms] //////////////////////////
// CONNECTION
// export const URL_BASE = process.env.REACT_APP_URL_BASE
// export const URL_BASE = "http://192.168.56.103:8080"
export const URL_BASE = "https://noiseportal.ekolagroup.cz/"
export const URL_TIMEOUT = 60000 // ms

// URLS
export const URL_GET_IF_USER_LOGGED = "/api/current_user"
export const URL_LOGIN = "/api/login"
export const URL_LOGOUT = "/api/logout"
export const URL_EVENTS = "/api/content/events"
export const URL_EVENT_DEVICES = "/api/content/event/#id/devices"
export const URL_EVENT_CONFIG = "/api/content/event/#id/config"
export const DEVICES_SPL_GRAPH_URL = "/api/content/event/#id/data/spl"
export const DEVICES_SPECTRUM_GRAPH_URL = "/api/content/event/#id/data/spectrum"
export const DEVICES_METEO_URL = "/api/content/event/#id/data/meteo"
export const DEVICES_WEEK_URL = "/api/content/event/#id/daily/spl"
// url for the old frontend
export const LEGACY_URL = "/legacy"


// CLIENT ROUTING
export const ROUTE_HOMEPAGE_AFTER_LOGIN = "/soundboard";
export const ROUTE_METEOBOARD = "/meteoboard";
export const ROUTE_IF_NOT_LOGIN = "/";
export const ROUTE_CONTACTS = "/contacts";

// CLIENT STATE
export const UNAUTHENTICATED_USER = 'Invalid Credentials';
export const AUTHENTICATION_ERROR = 'Authentication Error';
export const CONNECTION_ERROR = 'Can not connect to server';
export const REQUEST_ERROR = 'Request Error';


// LOCAL STORAGE KEYS
export const USER_IS_AUTHENTICATED_KEY = "isAuthenticated";
export const ACTUAL_EVENT_ID = 'actualEventId';


// MAPS WIDGET
export const EVENT_MAP_SETTINGS_KEY = 'mapListSettings';


// DEVICELIST WIDGET
export const EVENT_DEVICES_SELECTED_KEY = 'deviceslistSelectedData';
export const DEVICES_SELECTED_INTERVAL = "devicesSelectedInterval";



export const formatDate: string = "YYYY-MM-DD";
export const formatDateTime: string = "YYYY-MM-DDTHH:mm:ss";

export const locales = { "cs": csLocale };