/**
 * Function to override retarded modulo behavior in javascript.
 * See https://stackoverflow.com/a/17323608.
 */
export function mod(n: number, m: number): number {
    if (m === 0)
        return 0;

    return ((n % m) + m) % m;
}

/**
 * Returns energetic average of the given values. Used for LEQ averaging.
 * Function filters out NaN values.
 */
export function energeticAverage(values: number[]): number {
    if (values.length === 0)
        return NaN;

    var a: number[] = values.filter(v => v).map(v => Math.pow(10, v / 10));
    var ret: number = (a.reduce((s, v) => s + v)) / a.length;
    ret = 10 * Math.log10(ret);

    return ret;
}