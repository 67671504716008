import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useTranslation } from 'react-i18next';
import { Event } from "../../types/";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: "100%",
        },
        select: {
            marginBottom: "5px",
            color: "white",
            "& .MuiSvgIcon-root": {
                color: "white !important",
            },
        },
        label: {
            margin: "8px",
            color: "white",
            "&.Mui-focused": {
                color: "white",
            },
        }
    }),
);

interface Props {
    events: Event[],
    actualEvent: Event,
    onChange: (arg0: Event) => void
}

const SelectEvent = (props: Props) => {
    const { events, onChange, actualEvent } = props
    const classes = useStyles();
    const { t, } = useTranslation();

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        events.forEach(function (_event) {
            if (_event.name === event.target.value) {
                onChange(_event)
            }
        })

    };


    return (
        <div>
            <FormControl className={classes.formControl} focused>
                <InputLabel id="event-select-label" className={classes.label}>{t("Events")}</InputLabel>
                <Select
                    labelId="event-select-label"
                    id="event-selector"
                    value={actualEvent.name}
                    onChange={handleChange}
                    className={classes.select}
                >
                    {events.map(function (key) {
                        return <MenuItem key={key.name} value={key.name}>{key.name}</MenuItem>
                    })}
                </Select>
            </FormControl>
        </div>
    );
}
export default SelectEvent