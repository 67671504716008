import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAppContext } from "../Context/context";

// Constants
import { ROUTE_IF_NOT_LOGIN } from "../../config/Constants"

import SideMenu from "../../components/SideMenu/SideMenu";

interface IProps {
    exact?: boolean;
    path: string;
    component: React.ComponentType<any>;
}

/**
 * If logged pass children component, otherwise redirect
 * to page for not login.
 * It is for page view only if logged.
 * @param param0 
 */
const AuthenticatedRoute = ({
    component: Component,
    ...otherProps
}: IProps) => {
    const { isAuthenticated } = useAppContext()!;

    return (
        <Route {...otherProps}>
            {isAuthenticated ? (
                <SideMenu
                    children={<Component {...otherProps} />}
                ></SideMenu>
            ) : (
                    <Redirect to={ROUTE_IF_NOT_LOGIN} />
                )}
        </Route>
    )
}
export default AuthenticatedRoute;
