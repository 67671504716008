import { useState, useEffect } from "react";
import Grid from '@material-ui/core/Grid';

import dayjs from "dayjs";

import { useTranslation } from 'react-i18next';
import {
    DevicesGraphData, TimeRange, Devices,
    SelectedDevices, GraphData, SoundLimits
} from "../../types";
import { formatDateTime } from "../../config/Constants"
import LineLoading from "../animations/LineLoading"

import { mod, energeticAverage } from "../../libs/utils/math";
import { SoundStatistics } from "../../types";

import StatsPie from "./StatsPie";
import StatsValueDisplay from "./StatsValueDisplay";
import StatsPieContainer from "./StatsPieContainer";

const getStatistics = (y: number[], limit: number, tolerance = 2): SoundStatistics => {
    if (y == null)
        return { statistics: [] };

    if (y.length === 0)
        return { statistics: [] };

    if (limit == null)
        return { statistics: [], leq: energeticAverage(y) };

    const perCent = y.length / 100.0;
    const bellowLimit = (y.filter(v => v < limit - tolerance).length) / perCent;
    const aboveLimit = (y.filter(v => v > limit + tolerance).length) / perCent;
    const inLimit = 100.0 - (bellowLimit + aboveLimit);
    return {
        statistics: [bellowLimit, inLimit, aboveLimit],
        leq: energeticAverage(y)
    };
}

interface Props {
    eventDevices: Devices
    devicesData: DevicesGraphData
    selectedTimeRange: TimeRange
    selectedDevices: SelectedDevices
    isLoading: boolean
    soundLimits: SoundLimits
}

const getDeviceData = (devicesGraphData: GraphData[], selectedDevice: number): GraphData | undefined => {
    return Object.values(devicesGraphData).find(item => item.id === selectedDevice)

}

const getDefaultSelectedDevice = (_selectedDevices: SelectedDevices, _selectedDevice: number): number => {
    var key = Object.keys(_selectedDevices).find(key =>
        (_selectedDevice !== Number(key) && _selectedDevices[key] === true))
    if (key) {
        return Number(key)
    }
    return -1
}

const DeviceStatistics = (props: Props,) => {
    const { eventDevices, devicesData, selectedTimeRange, selectedDevices, isLoading, soundLimits } = props
    const { t, } = useTranslation();

    const [selectedDevice, setSelectedDevice] = useState<number>(() => {
        return getDefaultSelectedDevice(selectedDevices, -1)
    });
    const isSelectedDataValid = () => {
        if (Object.keys(selectedDevices).find(key => selectedDevices[key] === true) &&
            devicesData.data && getDeviceData(devicesData.data, selectedDevice)) {
            return true
        } else {
            return false
        }
    };
    const selectedTitle = () => { return isSelectedDataValid() ? eventDevices[selectedDevice.toString()].name : '-' };
    const selectedColor = () => { return isSelectedDataValid() ? eventDevices[selectedDevice.toString()].color : undefined }

    const [dayStats, setDayStats] = useState<SoundStatistics>({ statistics: [] });
    const [nightStats, setNightStats] = useState<SoundStatistics>({ statistics: [] });
    const [selectedStats, setSelectedStats] = useState<SoundStatistics>({ statistics: [] });

    const updateNightDayStats = (_selectedDevice: number) => {
        if (!isSelectedDataValid() || selectedDevices[_selectedDevice.toString()] === false) {
            setSelectedDevice(getDefaultSelectedDevice(selectedDevices, _selectedDevice))
            return
        }

        var data = getDeviceData(devicesData.data, _selectedDevice)
        if (data) {
            const y = data.raw.y;
            const x = data.raw.x.map((e) => dayjs(e));

            const yDay = y.filter((_, i) => { return x[i].hour() >= 6 && x[i].hour() < 22 });
            const yNight = y.filter((_, i) => { return x[i].hour() < 6 || x[i].hour() >= 22 });

            setDayStats(getStatistics(yDay, soundLimits.limits.day));
            setNightStats(getStatistics(yNight, soundLimits.limits.night));

            // GetStatistics bude vracet taky LEQ z danyho obdobi
        }
    }

    const updateSelectedStats = (_selectedDevice: number) => {
        if (!isSelectedDataValid() || selectedDevices[_selectedDevice.toString()] === false) {
            setSelectedDevice(getDefaultSelectedDevice(selectedDevices, _selectedDevice))
            return
        }

        var data = getDeviceData(devicesData.data, _selectedDevice)
        if (data) {
            const y = data.raw.y;
            const x = data.raw.x.map((e) => dayjs(e));

            const start = (selectedTimeRange == null) ? dayjs(devicesData.info.timeRange.start).format(formatDateTime) : selectedTimeRange.start;
            const end = (selectedTimeRange == null) ? dayjs(devicesData.info.timeRange.end).format(formatDateTime) : selectedTimeRange.end;

            const yDaySelection = y.filter(
                (_, i) => {
                    return x[i].isBetween(start, end) &&
                        (x[i].hour() >= 6 && x[i].hour() < 22)
                }
            )
            const yNightSelection = y.filter(
                (_, i) => {
                    return x[i].isBetween(start, end) &&
                        (x[i].hour() < 6 || x[i].hour() >= 22)
                }
            )

            const daySelectionStats = getStatistics(yDaySelection, soundLimits.limits.day);
            const nightSelectionStats = getStatistics(yNightSelection, soundLimits.limits.night);

            if (daySelectionStats.statistics.length === 0) {
                setSelectedStats(nightSelectionStats);
            }
            else if (nightSelectionStats.statistics.length === 0) { 
                setSelectedStats(daySelectionStats);
            }
            else {
                // TODO: spatny vypocet prumeru
                const stats = [
                    (daySelectionStats.statistics[0] + nightSelectionStats.statistics[0]) / 2,
                    (daySelectionStats.statistics[1] + nightSelectionStats.statistics[1]) / 2,
                    (daySelectionStats.statistics[2] + nightSelectionStats.statistics[2]) / 2
                ];

                const ySelection = y.filter((_, i) => { return x[i].isBetween(start, end) });
                setSelectedStats({ statistics: stats, leq: energeticAverage(ySelection) });
            }
        }
    }

    useEffect(() => {
        updateNightDayStats(selectedDevice)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [devicesData, selectedDevices]);

    useEffect(() => {
        updateSelectedStats(selectedDevice)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [devicesData, selectedDevices, selectedTimeRange]);

    const getPieCharts = () => {
        if (!isSelectedDataValid()) {
            return (
                <div className="NoDataLabel">
                    <span>{t("no_device_selected")}</span>
                </div>
            )
        }
        return (
            <Grid container spacing={0}>
                {/* Day */}
                <Grid item xs={4} sm={4} md={4} lg={4}>
                    {soundLimits.limits.enabled ?
                        <StatsPie
                            name={t("daytime_statistics")}
                            statistics={dayStats}
                            description="06:00–22:00 h"
                        ></StatsPie> :
                        <div style={{ marginTop: "40px" }}>
                            <StatsValueDisplay
                                name={t("daytime_statistics")}
                                value={dayStats.leq}
                                unit="LAeq,16h [dB]"
                                description="06:00–22:00 h"
                            ></StatsValueDisplay>
                        </div>
                    }
                </Grid>

                {/* Total */}
                <Grid item xs={4} sm={4} md={4} lg={4}>
                    {soundLimits.limits.enabled ?
                        <StatsPie
                            name={t("segment_statistics")}
                            statistics={selectedStats}
                            markerColor={selectedColor()}
                        ></StatsPie> :
                        <div style={{ marginTop: "40px" }}>
                            <StatsValueDisplay
                                name={t("segment_statistics")}
                                value={selectedStats.leq}
                                unit="LAeq,T [dB]"
                            ></StatsValueDisplay>
                        </div>
                    }
                </Grid>
                {/* Night */}
                <Grid item xs={4} sm={4} md={4} lg={4}>
                    {soundLimits.limits.enabled ?
                        <StatsPie
                            name={t("nighttime_statistics")}
                            statistics={nightStats}
                            description="22:00–06:00 h"
                        ></StatsPie> :
                        <div style={{ marginTop: "40px" }}>
                            <StatsValueDisplay
                                name={t("nighttime_statistics")}
                                value={nightStats.leq}
                                unit="LAeq,8h [dB]"
                                description="22:00–06:00 h"
                            ></StatsValueDisplay>
                        </div>
                    }
                </Grid>

            </Grid>
        )

    }

    const selectNextDevice = (forward: boolean = true) => {
        const keys = Object.keys(selectedDevices);
        const key = keys.indexOf(selectedDevice.toString());
        var nextKey;
        if (forward)
            nextKey = keys[mod(key + 1, keys.length)];
        else
            nextKey = keys[mod(key - 1, keys.length)];

        if (nextKey) {
            setSelectedDevice(Number(nextKey))
            updateNightDayStats(Number(nextKey))
            updateSelectedStats(Number(nextKey))
        }
    }

    const handleForwardCallback = () => {
        selectNextDevice();
    }
    const handleBackCallback = () => {
        selectNextDevice(false);
    }

    return (
        isLoading ? (
            <div style={{ width: "auto", height: "400px" }} >
                <LineLoading ></LineLoading>
            </div >
        ) : (
            <StatsPieContainer
                data={devicesData.data}
                content={getPieCharts()}
                selectTitle={selectedTitle}
                selectColor={selectedColor}
                onForwardCallback={handleForwardCallback}
                onBackCallback={handleBackCallback}
                showLimitLegend={soundLimits.limits.enabled}
            ></StatsPieContainer>

        )

    )
}

export default DeviceStatistics