import React from 'react'

// syles & assets

interface Props {

}

const Contacts = (props: Props) => {
    return (
        <div>
            Kontakty
        </div>
    )
}

export default Contacts
