// import React from 'react'
import Grid from '@material-ui/core/Grid';
import { useAppContext } from "../../services/Context/context";

// syles & assets
// components
import DashboardContent from "./content"

const Soundboard = () => {
  const { actualEvent, events } = useAppContext()!;

  return (
    <div>
      {events === undefined || actualEvent === undefined ? (
        <div>
          No events
        </div>

      ) : (

        <Grid container spacing={1}>
          <Grid item xs={12}>
            <DashboardContent event={actualEvent} ></DashboardContent>
          </Grid>
        </Grid>
      )}
    </div>
  )
}

export default Soundboard

