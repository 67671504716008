import React from "react";
// import { Button } from "react-bootstrap";
import Button from '@material-ui/core/Button';
import { FiRefreshCw } from 'react-icons/fi';
// styles
import "./LoaderButton.css"

interface Props {
    name: string,
    isLoading: boolean,
    disabled: boolean,
    color: 'default' | 'inherit' | 'primary' | 'secondary',
    type: 'button' | 'submit' | 'reset' | undefined,
    variant: 'outlined' | 'contained',
    fullWidth: true | false,
    className?: string
}

const LoaderButton = (props: Props) => {
    const { isLoading, disabled, color, name, className, ...other } = props
    return (
        <Button
            disabled={disabled || isLoading}
            {...other}
        >
            {isLoading ?
                <div className="LoaderButton">
                    < FiRefreshCw className="glyphicon glyphicon-refresh glyphicon-spin" />
                </div>
                : name}
        </Button>
    )
}

export default LoaderButton
