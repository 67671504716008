import { useState, useEffect } from "react";
import Plot from 'react-plotly.js';
import LineLoading from "../animations/LineLoading"
import { useTranslation } from 'react-i18next';

import dayjs from "dayjs";

import { isMobile } from "react-device-detect";
import {
  devicesMeteoDataType,
  meteoDataType,
  SegmentInterval,
  TimeRange
} from "../../types";
import { locales } from "../../config/Constants";
import { timerangeFromPlotyRelayoutEvent } from "../../libs/utils/plotly";

const defLayout = {
  title: "",
  legend: {
    orientation: "h", "y": isMobile ? -0.2 : -0.8,
    itemdoubleclick: false
  },
  yaxis: {
    title: 'Teplota [°C]',
  },
  yaxis2: {
    title: 'Srážky [mm/h]',
    anchor: 'x',
    overlaying: 'y',
    side: 'right'
  },
  plot_bgcolor: "#d8e4f4",
  xaxis: {
    title: 'Čas',
    type: "date",
    rangeslider: { "visible": !isMobile },
    rangeselector: {
      buttons: [
        {
          count: 1,
          label: "1H",
          step: "hour",
          stepmode: "backward",
        },
        {
          count: 3,
          label: "3H",
          step: "hour",
          stepmode: "backward",
        },
        {
          count: 6,
          label: "6H",
          step: "hour",
          stepmode: "backward",
        },
      ]
    },

  },


}
interface Props {
  devicesMeteoData: devicesMeteoDataType
  selectedInterval: SegmentInterval
  onRelayout: (arg0: TimeRange) => void
  isLoading: boolean
}

const DevicesGraphMeteo = (props: Props) => {
  const { devicesMeteoData, isLoading, selectedInterval,
    onRelayout } = props
  const { t, } = useTranslation();
  const [preparedData, setPreparedData] = useState<Plotly.Data[]>();
  const [layout, setLayout] = useState({});
  const [xRange, setXRange] = useState(["", ""])

  function prepareData(devicesMeteoData: devicesMeteoDataType, updateZoom: boolean) {

    var data: meteoDataType[] = devicesMeteoData.data
    var preparedData: Plotly.Data[] = []

    data.forEach(item => {

      if (item.isVisible) {
        if (selectedInterval in item.precipitation) {
          preparedData.push(
            {
              name: item.name + " (srážky)",
              x: item.precipitation[selectedInterval].x,
              y: item.precipitation[selectedInterval].y,
              showlegend: true,
              yaxis: 'y2',
              // type: "bar",
              hovertemplate: '%{y:.1f}',
              line: {
                dash: 'dot',
                width: 2,
                color: item.color
              }
            }
          )
        }

        if (selectedInterval in item.temperature) {
          preparedData.push(
            {
              name: item.name + " (teplota)",
              x: item.temperature[selectedInterval].x,
              y: item.temperature[selectedInterval].y,
              showlegend: true,
              mode: 'lines',
              connectgaps: false,
              hovertemplate: '%{y:.1f}',
              line: { color: item.color }
            }
          )
        }
      }

    })


    setPreparedData(preparedData)

    var range = []
    if (updateZoom) {
      range = [devicesMeteoData.info.timeRange.start, devicesMeteoData.info.timeRange.end];
      onRelayout(devicesMeteoData.info.timeRange);
      setXRange(range)
    } else {
      range = xRange
    }

    var shapes =
      [
        {
          fillcolor: "rgba(63, 81, 181, 0.2)",
          line: { "width": 0 },
          type: "rect",
          x0: devicesMeteoData.info.timeRange.start.split("T")[0] + " 22:00:00",
          x1: devicesMeteoData.info.timeRange.end.split("T")[0] + " 06:00:00",
          xref: "x",
          y0: 0,
          y1: 1,
          yref: "paper"
        }
      ]

    setLayout({
      ...defLayout,
      shapes: shapes,
      xaxis: {
        ...defLayout.xaxis,
        range: range
      },
      margin: {
        r: 50,
        t: 15,
      },
      // b: 0,
      font: {
        size: 12,
      },

    })
  }

  useEffect(() => {
    prepareData(devicesMeteoData, devicesMeteoData.updateZoom)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devicesMeteoData]);

  useEffect(() => {
    prepareData(devicesMeteoData, false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInterval]);


  function handleRelayout(ev: Plotly.PlotRelayoutEvent) {
    const { start, end } = timerangeFromPlotyRelayoutEvent(ev, devicesMeteoData.info.timeRange);
    onRelayout({ start, end });
    setXRange([start, end]);
  }



  return (
    <div className="view-device-graph">
      <div className="view-header" >
        <h3>{t("meteo_graph_title")}</h3>
      </div> {

        !preparedData || isLoading ? (
          <div style={{ width: "auto", height: "400px" }}>

            <LineLoading ></LineLoading>
          </div>
        ) : (
          <Plot
            data={preparedData}
            layout={layout}
            style={{ width: "auto", height: "400px" }}
            config={{
              responsive: true,
              displayModeBar: false,
              locale: dayjs.locale(),
              // @ts-ignore
              locales: locales
            }}
            onRelayout={handleRelayout}
          />
        )
      }
    </div >
  );
}

export default DevicesGraphMeteo;