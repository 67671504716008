// import React from 'react'
import { useEffect, useState, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import LineLoading from "../../../components/animations/LineLoading"
import { useAppContext } from "../../../services/Context/context";
import { DEVICES_METEO_URL } from "../../../config/Constants";
import {
	Devices, SelectedDevices, Event,
	SegmentInterval, TimeRange,
	devicesMeteoDataType
} from "../../../types";
import clsx from 'clsx';
import { devicesLib } from "../../../libs";

import dayjs from "dayjs";

// syles & assets
import { useBaseStyles } from "../../../styles"
import { generateDay } from '../../../libs/utils/time';

// components
import DevicesPolarWind from "../../../components/DevicesPolarWind";
import DevicesGraphMeteo from "../../../components/DevicesGraphMeteo";
import DevicesGraphWind from "../../../components/DevicesGraphWind";


interface Props {
	event: Event
	devices: Devices
	selectedDevices: SelectedDevices
	selectedInterval: SegmentInterval
	selectedDate: string
	isEnabled: boolean
}

const MeteoData = (props: Props) => {
	const { getDataFromTimeRange } = useAppContext()!;

	// Props
	const { event, devices, selectedDevices,
		selectedInterval, selectedDate, isEnabled } = props

	// styles
	const classes = useBaseStyles();

	// stats
	const [isLoadingMeteo, setIsLoadingMeteo] = useState<boolean>(false);

	// Meteo devices
	const [devicesMeteoData, setDevicesMeteoData] = useState<devicesMeteoDataType>();

	// Time Range etc. for statics
	const [devicesGraphTimerange, setDevicesGraphTimerange] = useState<TimeRange>(
		generateDay(dayjs(selectedDate), 6)
	)



	useEffect(() => {
		if (!isEnabled) { return }
		const fetchEventMeteoGraph = async (updateZoom: boolean) => {

			setIsLoadingMeteo(true)
			// METEO DATA
			const _meteo = await getDataFromTimeRange(
				DEVICES_METEO_URL,
				generateDay(dayjs(selectedDate), 6),
				event.id);

			var meteo: devicesMeteoDataType = _meteo.data

			if (meteo && meteo.data.length > 0) {
				meteo.data = devicesLib.filterDevicesMeteoBySelected(meteo.data, selectedDevices)
				meteo.updateZoom = updateZoom
				setDevicesMeteoData(meteo)
			}
			setIsLoadingMeteo(false)
		}
		fetchEventMeteoGraph(true)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedDate, event]);


	useMemo(() => {
		if (!isEnabled) { return }
		if (devicesMeteoData) {
			setDevicesMeteoData({
				...devicesMeteoData,
				data: devicesLib.filterDevicesMeteoBySelected(devicesMeteoData.data, selectedDevices)
			})
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedDevices]);


	function handleDevicesGraphRelayout(data: TimeRange) {
		//  for statics widget
		setDevicesGraphTimerange(data);
	}

	const autoPaper = clsx(classes.paper, classes.autoHeight);
	return (
		devices && selectedDevices && devicesMeteoData ? (
			<Grid container spacing={2}>

				<Grid item xs={12} md={12} lg={12}>
					<Paper className={autoPaper}>
						<DevicesGraphMeteo
							devicesMeteoData={devicesMeteoData}
							isLoading={isLoadingMeteo}
							selectedInterval={selectedInterval}
							onRelayout={() => { }}
						></DevicesGraphMeteo>
					</Paper>
				</Grid>
				<Grid item xs={8} md={8} lg={8}>
					<Paper className={autoPaper}>
						<DevicesGraphWind
							devicesMeteoData={devicesMeteoData}
							isLoading={isLoadingMeteo}
							selectedInterval={selectedInterval}
							onRelayout={handleDevicesGraphRelayout}
						></DevicesGraphWind>
					</Paper>
				</Grid>
				<Grid item xs={4} md={4} lg={4}>
					<Paper className={autoPaper}>
						<DevicesPolarWind
							devicesMeteoData={devicesMeteoData}
							devices={devices}
							selectedTimeRange={devicesGraphTimerange}
							isLoading={isLoadingMeteo}
						></DevicesPolarWind>
					</Paper>
				</Grid>


			</Grid>

		) : (
			<div style={{ width: "auto", height: "400px" }}>
				<LineLoading ></LineLoading>
			</div>
		)

	)
}

export default MeteoData

